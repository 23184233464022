import './Loading.scss';
import {Grid, Typography}           from '@mui/material';
import BoxLogo                      from 'assets/img/kaktus.svg';
import {Progress}                   from 'react-sweet-progress';
import React, {useEffect, useState} from 'react';


export default function Loading({pageHeight = 900, withProgress = false, currentProgress = undefined, secondsToFinish = 5}) {

  const [progress, setProgress] = useState(null);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    if (!currentProgress) {
      const timerId = setInterval(() => {
        setSeconds(seconds + 0.1);
        setProgress(Math.round(seconds / secondsToFinish * 100));
      }, 100);
      if (progress >= 100) {
        clearInterval(timerId);
      }
      return () => clearInterval(timerId);
    }
  });

  return (
    <Grid
      container
      justifyContent={'center'}
      alignContent={'center'}
      alignItems={'center'}
      className={'loading-container'}
      style={{ height: pageHeight }}
    >
      <Grid
        item
        md={4}
        container
        justifyContent={'center'}
        alignContent={'center'}
        alignItems={'center'}
      >
        <Typography variant="h3" component="h4">
          <div className='lds-css ng-scope'>
            <div className={'lds-eclipse'}>
              <img alt='loading' src={BoxLogo} className={'loading-logo'}></img>

              <div></div>
            </div>

          </div>
        </Typography>
        {withProgress &&
        <Progress
          percent={currentProgress || progress}
          status="active"
          theme={{active: {color: '#32bee1'}}}
        />}
      </Grid>
    </Grid>
  );
}
