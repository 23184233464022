import React, {Suspense, useEffect} from 'react';
import {useGlobal} from 'reactn';
import {BrowserRouter, Route, Routes, useLocation} from 'react-router-dom';
import AppLayout from './layouts/App/AppLayout.js';
import Loading from './components/Loading/Loading';
import {getToken, getUserInfo, logout} from './services/auth';
import {ThemeProvider, StyledEngineProvider} from '@mui/material/styles';
import {theme} from './styles/theme/theme';
import {LocalizationProvider} from '@mui/x-date-pickers';
import deLocale from 'date-fns/locale/de';
import enLocale from 'date-fns/locale/en-US';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import i18n from './i18n';


const App = () => {
  if (!localStorage.getItem('lang')) {
    localStorage.setItem('lang', 'de');
  }

  const [user, setUser] = useGlobal('userInfo');
  const [history, setHistory] = useGlobal('history');

  useEffect(() => {
    getUserInfo().then(({data}) => {
      setUser(data);
      if (data.language) i18n.changeLanguage(data.language).catch(console.error);
    });
  }, []);

  if (!window.location.pathname.includes('bundle')) {
    document.title = 'KREATIZE Kupplias';
  }

  const isAuthenticated = getToken();
  if (!isAuthenticated) {
    logout();
  }

  if (!user) {
    return <Loading />;
  }

  return (
    <Suspense fallback={<Loading />}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={i18n.language === 'de' ? deLocale : enLocale}>
            <BrowserRouter history={history}>
              <Routes>
                <Route path='/*' element={<AppLayout path='/' history={history} user={user} />} />
              </Routes>
            </BrowserRouter>

          </LocalizationProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </Suspense>
  );
};

export default App;
