import i18n from '../i18n';

export const toDisplayCurrency = (value) => {
  if (!value) {
    return null;
  }

  if (typeof value !== 'number') {
    value = parseFloat(value);
  }

  return new Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR'}).format(value);
};

/**
 * @description Convert ISO timestamp into app defined display format for the user
 * @example 2019-08-21T06:51:22+00:00 to 21st Aug 2019
 * @param {*} timestampDate @type string
 */
export const toDisplayDate = timestampDate => {
  const options = {year: 'numeric', month: 'short', day: 'numeric'} as any;
  return new Intl.DateTimeFormat(i18n.language === 'de' ? 'DE-de' : 'EN-gb', options).format(new Date(timestampDate));
};

/**
 * @description Convert ISO timestamp into app defined display format for the user
 * @example 2019-08-21T06:51:22+00:00 to 21st Aug 2019 06:40am
 * @param {*} timestampDate @type string
 */
export const toDisplayDatetime = timestampDate => {
  const options = {year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric'} as any;
  return new Intl.DateTimeFormat(i18n.language === 'de' ? 'DE-de' : 'EN-gb', options).format(new Date(timestampDate));
};

export const toDisplayUnit = (unitKey: string) => {
  const mapping = {
    euro_per_hour: '€/hr',
    euro_per_kg: '€/kg',
    euro_per_position: '€/position',
    g: 'g',
    hrc: 'hrc',
    hv: 'hv',
    kg: 'kg',
    µm: 'µm',
    mm: 'mm',
    mm_cubed: '㎣',
    mm_squared: '㎟',
    µm_plus_minus: 'µm ±',
    percent: '%',
    pieces: 'pieces',
    ral_nr: 'RAL',
    working_days: 'Working Days',
  }

  return mapping[unitKey]
}