import React, {useEffect} from 'react';
import {Navigate, Route, Routes, useLocation} from 'react-router-dom';
import routes from 'routes.js';
import ReactTooltip from 'react-tooltip';
import {Container} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {BundleDetails} from '../../../../components/BundleDetails/BundleDetails';
import {OrderDetails} from '../../../../components/OrderDetails/OrderDetails';
import PartDetails from '../../../../components/PartDetails/PartDetails';


const pathHistory = [];

export const MainContainer = (props) => {

  const navigate = useNavigate();
  const location = useLocation();

  // Get path history and save in array with a max length of 10
  useEffect(() => {
    pathHistory.push(location.pathname);
    if (pathHistory.length > 10) {
      pathHistory.shift();
    }
  }, [location]);

  const getRoutes = () => {
    return routes.map((route, key) => {
      if (route.component) {
        return <Route path={route.layout + route.path} element={<route.component {...props} navigate={navigate} history={props.history} pathHistory={pathHistory} />} key={key} />;
      }
    });
  };

  return (
    <Container style={{...props.style}} maxWidth={false}>
      <Routes>
        <Route path={'/'} element={<Navigate to={'/orders'} replace />} />
        <Route path={'/bundles/:id'} element={<BundleDetails navigate={navigate} history={props.history} pathHistory={pathHistory} />} />
        <Route path={'/orders/:id'} element={<OrderDetails navigate={navigate} history={props.history} pathHistory={pathHistory} />} />
        <Route path={'/parts/:id'} element={<PartDetails navigate={navigate} history={props.history} pathHistory={pathHistory} />} />
        {getRoutes(routes)}
      </Routes>
      <ReactTooltip effect='solid' type='error' />
    </Container>
  );
};
export default MainContainer;
