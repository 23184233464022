import {Box, IconButton, Paper} from '@mui/material';
import {DataGridPro} from '@mui/x-data-grid-pro';
import React, {Component} from 'react';
import {getOrderedPartConfigs} from '../../services/knest';
import {ArrowCircleRight} from '@mui/icons-material';


type Props = {
  navigate: Function
}
type State = {
  loading: boolean,
  columns: any[],
  rows: any[]
}

export class PartsTable extends Component <Props, State> {

  constructor(props) {
    super(props);

    this.state = {
      columns: [
        {field: 'id', headerName: 'ID', flex: 0.8},
        {field: 'orderedPart', headerName: 'Name', flex: 3, valueGetter: ({value}) => value.name},
        {field: 'orderedPartListId', headerName: 'Order ID', flex: 1.5},
        {field: 'batchSize', headerName: 'Batch Size', flex: 1.5},
        {field: 'totalCost', headerName: 'Total Customer Price', flex: 3.5},
        {
          field: 'bundleOrderedPartConfigTeasers',
          headerName: '# of assigned Bundles',
          flex: 2,
          valueGetter: ({value}) => new Set(value.map(x => x.bundleId)).size,
        },
        {
          field: 'action',
          headerName: '',
          flex: 0.5,
          align: 'center',
          renderCell: (params) => <IconButton onClick={() => this.props.navigate(`/parts/${params.row.id}`)}><ArrowCircleRight
            color={'primary'}
          /></IconButton>,
        },
      ],
      rows: [],
      loading: true,
    };
  }

  async componentDidMount() {
    const orderedPartConfigs = await getOrderedPartConfigs({
      where: {orderedPartListTeaser: {isNot: {statusKey: 'open'}}},
      orderBy: {id: 'desc'},
    });
    this.setState({
      rows: orderedPartConfigs,
      loading: false,
    });
  }

  render() {
    return (
      <Paper sx={{height: '100%'}}>
        <Box sx={{height: '100%'}}>
          <DataGridPro
            onRowClick={(params) => this.props.navigate(`/parts/${params.row.id}`)}
            rows={this.state.rows}
            columns={this.state.columns}
            loading={this.state.loading}
          />
        </Box>
      </Paper>
    );
  }
}