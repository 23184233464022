import React from 'react';
import {Grid, Typography} from '@mui/material';
import {PartsTable} from '../components/PartsTable/PartsTable';


const Parts = (props: any) => {
  return (
    <Grid container alignContent={'flex-start'} sx={{height: '100%'}}>
      <Grid item xs={12} marginTop={'20px'}>
        <Typography variant={'subtitle2'} sx={{color: '#b8b8b8'}}>All Ordered Parts</Typography>
      </Grid>
      <Grid item xs={12} marginBottom={'15px'}>
        <Typography variant={'h2'}>Parts</Typography>
      </Grid>
      <Grid item xs={12} height={'87vh'}>
        <PartsTable {...props} />
      </Grid>
    </Grid>
  );
};

export default Parts;
