import {MuiBaseModal} from '../MuiBaseModal';
import {Button, Grid, IconButton, Tooltip, Typography} from '@mui/material';
import {ReactElement, useState} from 'react';
import {InfoIcon} from '../../AnimatedIcons/AnimatedIcons';


type Props = {
  title: string,
  buttonText?: string,
  successMessage?: string,
  onSubmit: Function,
  onClose?: Function,
  disabled?: boolean,
  color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning' | undefined
  iconButtonIcon?: any,
  tooltip?: string
}

export const ConfirmModal = ({title, buttonText, successMessage, onSubmit, onClose = () => {}, disabled, color, iconButtonIcon, tooltip}: Props) => {

  const [open, setOpen] = useState(false);
  const [stage, setStage] = useState('content');

  const handleSubmit = async () => {
    setStage('loading');
    await new Promise(resolve => setTimeout(resolve as any, 1000));
    try {
      await onSubmit();
      setStage('success');
      //    setStage('content');
    }
    catch(err) {
      setStage('failure');
    }
  };

  const handleToggle = async () => {
    setOpen(!open);
    onClose();
  };

  const content = () => {
    return (
      <Grid container>
        <Grid item xs={12} display={'flex'} justifyContent={'center'} marginTop={5}>
          <InfoIcon />
        </Grid>
        <Grid item xs={12} display={'flex'} justifyContent={'center'}>
          <Typography variant={'h5'}>{title}</Typography>
        </Grid>
      </Grid>
    );
  };

  const footer = () => {
    if (stage !== 'content') {
      return <></>;
    }
    return (
      <Grid container paddingX={5}>
        <Grid item xs={6} paddingX={2}>
          <Button onClick={handleToggle} color={'secondary'} fullWidth>No</Button>
        </Grid>
        <Grid item xs={6} paddingX={2}>
          <Button onClick={handleSubmit} fullWidth>Yes</Button>
        </Grid>
      </Grid>
    );
  };

  return <MuiBaseModal
    button={
      <Tooltip title={tooltip || ''}>
        <span>
          {iconButtonIcon ?
            <IconButton onClick={handleToggle} disabled={disabled}>{iconButtonIcon}</IconButton> :
            <Button variant={'outlined'} color={color} onClick={handleToggle} disabled={disabled}>{buttonText}</Button>}
        </span>
      </Tooltip>
    }
    open={open}
    onToggle={handleToggle}
    content={content()}
    footer={footer()}
    stage={stage as any}
    successMessageTitle={successMessage || 'Yeeey'}
    height={'400px'}
    width={'700px'}
  />;
};