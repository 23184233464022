import React, {Component} from 'reactn';
import {Box, Breadcrumbs, Button, Chip, Grid, IconButton, Link, Paper, Tab, Tooltip, Typography} from '@mui/material';
import {constructOrderedPartConfigThumbnailURL, deleteBundle, downloadOrderedPartConfigFiles, getOrderedPartConfig} from '../../services/knest';
import _ from 'lodash';
import {ArrowCircleRight, Delete, Download} from '@mui/icons-material';
import {DataGridPro} from '@mui/x-data-grid-pro';
import {TabList, TabPanel, TabContext} from '@mui/lab';
import {getColorForStatus} from '../../utils/colormapping';
import Thumbnail from '../Thumbnails/Thumbnails';
import {UploadBlankedDrawingsModal} from '../Modals/UploadBlankedDrawingsModal/UploadBlankedDrawingsModal';
import {toDisplayUnit} from '../../utils/formatters';
import {withTranslation} from 'react-i18next';
import {Link as RouterLink} from 'react-router-dom';

type Props = {
  navigate: Function,
  history: any,
  pathHistory: string[]
  t: Function,
}
type State = {
  part: any
  bundles: any[],
  selectedTab: 'bundles' | 'details'
}

class PartDetails extends Component <Props, State> {
  constructor() {
    super();
    this.state = {
      part: null,
      bundles: [],
      selectedTab: 'details',
    };
  }

  getPartAndSaveInState = async () => {
    const partId = window.location.pathname.split('/').pop() as any;
    const part = await getOrderedPartConfig(partId);
    const bundles = _.uniqBy(part.bundleOrderedPartConfigTeasers.map(bopc => bopc.bundle).flat(), 'id');
    this.setState({part, bundles});
  };

  async componentDidMount() {
    await this.getPartAndSaveInState();
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState?.part?.id !== this.state.part?.id) {
      await this.getPartAndSaveInState();
    }
  }

  render() {
    const {t} = this.props;
    const prevPath = this.props.pathHistory[this.props.pathHistory.length - 2] || [] as any;
    return !this.state.part ? <div>Loading...</div> : (
      <Grid container alignContent={'flex-start'}>
        <Grid item container>
          <Grid item xs={12} marginTop={'20px'}>
            <Breadcrumbs aria-label={'breadcrumbs'}>
              <Link
                component={'button'}
                onClick={() => this.props.navigate(`/orders/${this.state.part.orderedPartListId}`)}
                variant={'subtitle2'}
                underline={'hover'}
                sx={{color: '#b8b8b8'}}
              >Order KMS-{this.state.part.orderedPartListId}
              </Link>
              {prevPath.includes('bundle') &&
                <Link
                  component={'button'}
                  onClick={() => this.props.navigate(`/bundles/${prevPath.split('/')[2]}`)}
                  variant={'subtitle2'}
                  underline={'hover'}
                  sx={{color: '#b8b8b8'}}
                >Bundle KAKTUS-{prevPath.split('/')[2]}
                </Link>
              }
              <Link variant={'subtitle2'} underline={'hover'} sx={{color: '#b8b8b8'}}>Part Details</Link>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12} marginBottom={'15px'}>
            <Typography variant={'h2'}>Part</Typography>
          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={12} marginBottom={1}>
            <Paper sx={{height: '100%', backgroundColor: '#F3F3F3', borderStyle: 'solid', border: 1, boxShadow: 'none'}}>
              <Grid item container xs={12} paddingX={4} paddingY={'15px'} columns={5}>
                <Grid item xs={1} display={'grid'} justifyContent={'center'}>
                  <Grid item xs={12}>
                    <Typography variant={'h6'}>{this.state.part.orderedPart.name}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant={'subtitle2'}>Part Name</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={1} display={'grid'} justifyContent={'center'}>
                  <Grid item xs={12}>
                    <Typography variant={'h6'}>{this.state.part.orderedPart.articleNr}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant={'subtitle2'}>Article Number</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={1} display={'grid'} justifyContent={'center'}>
                  <Grid item xs={12}>
                    <Typography variant={'h6'}>{this.state.part.orderedPart.drawingNr}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant={'subtitle2'}>Drawing Number</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={1} display={'grid'} justifyContent={'center'}>
                  <Grid item xs={12}>
                    <Typography variant={'h6'}>{this.state.part.batchSize}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant={'subtitle2'}>Batch Size</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={1} display={'grid'} justifyContent={'center'}>
                  <Grid item xs={12}>
                    <Typography variant={'h6'}>{this.state.part.bundleOrderedPartConfigTeasers[0]?.materialNames?.join('/') || this.state.part.materialName}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant={'subtitle2'}>Material</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} height={'200px'}>
            <TabContext value={this.state.selectedTab}>
              <Box>
                <Grid container display={'flex'} alignItems={'center'} spacing={2}>
                  <Grid item xs={6}>
                    <TabList onChange={(e, value) => this.setState({selectedTab: value})} aria-label="lab API tabs example">
                      <Tab label={`Part Details`} value="details" />
                      <Tab label={`Bundles  (${this.state.bundles.length})`} value="bundles" />
                    </TabList>
                  </Grid>
                  <Grid item xs={6} container spacing={2} justifyContent={'flex-end'}>
                    <Grid item>
                      <UploadBlankedDrawingsModal
                        orderedPartConfig={this.state.part}
                        updateState={this.getPartAndSaveInState}
                      />
                    </Grid>
                    <Grid item>
                      <Button
                        onClick={() => downloadOrderedPartConfigFiles(this.state.part.id, 'blanked_technical_drawing')} variant={'outlined'}
                        startIcon={<Download fontSize={'large'} />}
                      >
                        Download Blanked Drawings
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        onClick={() => downloadOrderedPartConfigFiles(this.state.part.id)} variant={'outlined'}
                        startIcon={<Download fontSize={'large'} />}
                      >
                        Download Files
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
              <TabPanel value="bundles">
                <Grid item xs={12} sx={{height: 'calc(100vh - 285px)'}}>
                  <DataGridPro
                    hideFooter
                    columns={[
                      {field: 'bundleKid', headerName: 'KAKTUS ID', flex: 0.5},
                      {
                        field: 'statusKey',
                        headerName: 'Status',
                        flex: 1,
                        renderCell: ({value}) => <Chip color={getColorForStatus(value)} label={value} />,
                      },
                      {field: 'companyName', headerName: 'Supplier', flex: 1},
                      {field: 'numberOfPartConfigs', headerName: '# of Parts', flex: 1},
                      {
                        field: 'action',
                        headerName: '',
                        flex: 0.4,
                        align: 'right',
                        type: 'actions',
                        renderCell: (params) => <Box>
                          {params.row.statusKey === 'new' &&
                            <Tooltip placement={'top'} title={'Delete this bundle'}>
                              <IconButton onClick={() => deleteBundle(params.row.id).then(async () => await this.getPartAndSaveInState())}>
                                <Delete color={'error'} />
                              </IconButton>
                            </Tooltip>}
                          <RouterLink to={`/bundles/${params.row.id}`}>
                            <IconButton>
                              <ArrowCircleRight color={'primary'} />
                            </IconButton>
                          </RouterLink>
                        </Box>,
                      },
                    ]}
                    rows={this.state.bundles}
                    onRowClick={(params, event) => {
                      if (event.metaKey || event.ctrlKey || event.shiftKey) {
                        return window.open(`/bundles/${params.row.id}`, '_blank');
                      }

                      this.props.navigate(`/bundles/${params.row.id}`);
                    }}
                  />
                </Grid>
              </TabPanel>
              <TabPanel value={'details'}>
                <Paper sx={{boxShadow: 'none', border: '1px solid rgba(224, 224, 224, 1)', padding: 5, height: 'calc(100vh - 290px)'}}>
                  <Grid item container xs={12} height={'100%'}>
                    <Grid item xs={4}>
                      <Grid item xs={12} justifyContent={'center'} display={'flex'}>
                        <Thumbnail id={this.state.part.id} numberOfBlankedDrawings={2} src={constructOrderedPartConfigThumbnailURL(this.state.part.id)} />
                      </Grid>
                    </Grid>
                    <Grid item xs={8}>
                      <Grid item xs={12}>
                        <Box display={'flex'} justifyContent={'flex-end'}>
                          {this.state.part.isHighWeight && <Chip label={'High Weight (>80kg)'} color={'error'} />}
                          {this.state.part.isReorder && <Chip label={'Reorder'} color={'error'} sx={{marginLeft: 2}} />}
                        </Box>
                      </Grid>
                      <Grid container paddingY={3} paddingX={5}>
                        <Grid item xs={6}>
                          <Grid item xs={12} marginBottom={1}>
                            <Typography variant={'h5'}>Part Features:</Typography>
                          </Grid>
                          <Grid item xs={12} display={'flex'}>
                            <ul style={{marginLeft: 20, minWidth: 250}}>
                              <li>
                                <Grid container xs={12}>
                                  <Grid item xs={8}>
                                    <Typography variant={'h6'}>Complexity:</Typography>
                                  </Grid>
                                  <Grid item xs={4} display={'flex'} alignItems={'center'}>
                                    <Typography variant={'body1'}>{this.state.part.complexityScore > 0.5 ? 'High' : this.state.part.complexityScore > 0 ? 'Low' : 'Unrecognized'}</Typography>
                                  </Grid>
                                </Grid>
                              </li>
                              {this.state.part.orderedPart.orderedPartPartFeatures?.map(oppf =>
                                <li>
                                  <Grid container xs={12}>
                                    <Grid item xs={8}>
                                      <Typography variant={'h6'}>{oppf.partFeatureKey}:</Typography>
                                    </Grid>
                                    <Grid item xs={4} display={'flex'} alignItems={'center'}>
                                      <Typography variant={'body1'}>{oppf.value} {toDisplayUnit(oppf.partFeature?.unitKey)}</Typography>
                                    </Grid>
                                  </Grid>
                                </li>)}
                            </ul>
                          </Grid>
                        </Grid>
                        <Grid item xs={6}>
                          <Grid xs={12} marginBottom={1}>
                            <Typography variant={'h5'}>Processes:</Typography>
                          </Grid>
                          <Grid xs={12}>
                            <ul style={{marginLeft: 20}}>
                              {this.state.part.orderedPartConfigProcesses.map(x => <li style={{marginBottom: 4}}>
                                  <Chip
                                    color={x.process.class === 'process' ? 'primary' : 'secondary'}
                                    sx={{marginRight: '4px'}}
                                    label={t(`processes:${x.process.key}`)}
                                  />
                                </li>,
                              )}
                            </ul>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </TabPanel>
            </TabContext>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withTranslation(['processes', 'processFeatures'])(PartDetails);
