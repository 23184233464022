import {
  Card,
  CardContent,
  Chip,
  Collapse,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import React, {Component, ReactElement} from 'react';
import {KeyboardArrowDown, KeyboardArrowUp} from '@mui/icons-material';
import noPreview from '../../assets/img/no-preview.jpg';
import i18n from '../../i18n';
import {getColorForStatus} from '../../utils/colormapping';
import {constructOrderedPartConfigThumbnailURL} from '../../services/knest';
import {toDisplayUnit} from '../../utils/formatters';

type Props = {
  orderedPartConfig: any
  buttons?: ReactElement
}
type State = {
  collapse: boolean
}

export class CollapsablePartDetails extends Component <Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
    };
  }

  render() {
    const {orderedPartConfig} = this.props;
    const {t} = i18n as any;

    return (
      <Grid item xs={12}>
        <Card>
          <CardContent style={{padding: 16}}>
            <Grid
              container
              rowSpacing={2}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <Grid item xs={2} container rowSpacing={1} alignItems={'center'} justifyContent={'center'}>
                <Grid item xs={2}>
                  <IconButton size={'small'} onClick={() => this.setState({collapse: !this.state.collapse})}>
                    {this.state.collapse ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                  </IconButton>
                </Grid>
                <Grid item xs={10}>
                  <img
                    id={`part-config-${orderedPartConfig.id}-thumbnail`}
                    src={constructOrderedPartConfigThumbnailURL(orderedPartConfig.id)}
                    alt="Part Config Thumbnail"
                    style={{
                      height: 120,
                      maxWidth: '100%',
                      backgroundSize: 'contain',
                    }} //@ts-ignore
                    onError={() => {document.getElementById(`part-config-${orderedPartConfig.id}-thumbnail`).src = noPreview;}}
                  />
                </Grid>
              </Grid>
              <Grid item xs={10} container justifyContent={'space-between'} rowSpacing={1}>
                <Grid item xs={12} container alignItems={'center'} spacing={1}>
                  <Grid item sx={{marginRight: 5, maxWidth: 5 / 18}}>
                    <Typography variant={'h6'}>
                      {orderedPartConfig.orderedPart.name}
                    </Typography>

                  </Grid>
                  <Grid item sx={{maxWidth: 1 / 3}}>
                    <Chip
                      variant={'outlined'}
                      color={'secondary'}
                      label={`Article Number: ${orderedPartConfig.orderedPart.articleNr}`}
                    />

                  </Grid>
                  <Grid item sx={{maxWidth: 1 / 3}}>
                    <Chip
                      variant={'outlined'}
                      color={'secondary'}
                      label={`Drawing Number: ${orderedPartConfig.orderedPart.drawingNr}`}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} container justifyContent={'space-between'} alignItems={'center'}>
                  <Grid item xs={4} style={{fontStyle: 'italic', fontSize: '1rem'}}>
                    <Typography variant={'body1'}>
                      Ordered Part Config ID: {orderedPartConfig.id}
                    </Typography>
                  </Grid>
                  <Grid item>
                    {orderedPartConfig.kaktusStatuses?.map(key => <Chip color={getColorForStatus(key)} sx={{marginLeft: 1}} label={key} />)}
                    {orderedPartConfig.bundleOrderedPartConfigTeasers?.some(bopct => bopct.isInComplaint) && <Chip color={'error'} sx={{marginLeft: 1}} label={'⚠️ In Complaint'} />}
                  </Grid>
                  <Grid marginRight={3}>
                    {this.props.buttons as any}
                  </Grid>
                </Grid>
                <Grid item xs={12} container spacing={1} alignItems={'center'}>
                  <Grid item>
                    <Chip
                      variant={'outlined'}
                      color={'primary'}
                      label={`Material Name: ${orderedPartConfig.materialName}`}
                    />
                  </Grid>
                  <Grid item>
                    <Chip
                      variant={'outlined'}
                      color={'primary'}
                      label={`Batch Size: ${orderedPartConfig.batchSize}`}
                    />
                  </Grid>
                  <Grid item>
                    <Chip
                      variant={'outlined'}
                      color={'primary'}
                      label={`Main Processes: ${orderedPartConfig.orderedPartConfigProcesses.filter(p => p.process.class === 'process')
                        .map(p => t(`processes:${p.process.key}`))
                        .join(', ')}`}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} style={{padding: 0}}>
                <Collapse in={this.state.collapse}>
                  <Grid container paddingY={3} paddingX={5}>
                    <Grid item xs={6}>
                      <Grid item xs={12}>
                        <Typography variant={'h6'}>Part Features:</Typography>
                      </Grid>
                      <Grid item xs={12} display={'flex'}>
                        <ul style={{marginLeft: 20, minWidth: 250}}>
                          <li>
                            <Grid container xs={12}>
                              <Grid item xs={8}>
                                <Typography variant={'h6'}>Complexity:</Typography>
                              </Grid>
                              <Grid item xs={4} display={'flex'} alignItems={'center'}>
                                <Typography variant={'body1'}>{orderedPartConfig.complexityScore > 0.5 ? 'High' : orderedPartConfig.complexityScore > 0 ? 'Low' : 'Unrecognized'}</Typography>
                              </Grid>
                            </Grid>
                          </li>
                          {orderedPartConfig.orderedPart.orderedPartPartFeatures?.map(oppf =>
                            <li>
                              <Grid container xs={12}>
                                <Grid item xs={8}>
                                  <Typography variant={'h6'}>{oppf.partFeatureKey}:</Typography>
                                </Grid>
                                <Grid item xs={4} display={'flex'} alignItems={'center'}>
                                  <Typography variant={'body1'}>{oppf.value} {toDisplayUnit(oppf.partFeature?.unitKey)}</Typography>
                                </Grid>
                              </Grid>
                            </li>)}
                        </ul>
                      </Grid>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid xs={12}>
                        <Typography variant={'h6'}>Processes</Typography>
                      </Grid>
                      <Grid xs={12}>
                        <ul style={{marginLeft: 20, minWidth: 250}}>
                          {orderedPartConfig.orderedPartConfigProcesses.map(x => <li><Typography>{t(`processes:${x.process.key}`)}</Typography></li>)}
                        </ul>
                      </Grid>
                    </Grid>
                  </Grid>
                </Collapse>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    );
  }
}
