import React, {Component} from 'reactn';
import {
  Box,
  Breadcrumbs,
  Chip,
  Collapse,
  Grid,
  IconButton,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import {CollapsablePartDetails} from '../CollapsablePartDetails/CollapsablePartDetails';
import {
  deleteBundle,
  deleteBundleOrderedPartConfig,
  getBundle,
  releaseMultipleBundles,
  updateBundleStatus,
  updateTargetCost,
} from '../../services/knest';
import {ArrowCircleRight, KeyboardArrowDown, KeyboardArrowUp, RemoveCircle} from '@mui/icons-material';
import {AddPartToBundleModal} from '../Modals/AddPartToBundleModal/AddPartToBundleModal';
import {ConfirmModal} from '../Modals/ConfirmModal/ConfirmModal';
import {toDisplayCurrency, toDisplayDate} from '../../utils/formatters';
import {Link as RouterLink} from 'react-router-dom';

type Props = {
  navigate: Function,
  pathHistory: string[]
}
type State = {
  collapse: boolean,
  bundle: any,
  addPartModalOpen: boolean,
  allDrawingsUploaded: boolean
}

export class BundleDetails extends Component <Props, State> {
  constructor() {
    super();
    this.state = {
      collapse: false,
      addPartModalOpen: true,
      bundle: null,
      allDrawingsUploaded: false,
    };
  }

  getBundleAndSaveInState = async () => {
    const bundleId = window.location.pathname.split('/').pop() as any;
    const bundle = await getBundle(bundleId);

    const allDrawingsUploaded = bundle?.bundleOrderedPartConfigs.reduce((acc, bopc) => {
      const germanDrawing = bopc.orderedPartConfig.orderedPartConfigOrderedAttachmentTeasers?.find(
        orderedAttachment => orderedAttachment.attachmentTypeKey === 'blanked_technical_drawing' && orderedAttachment.languageKey === 'de');
      const englishDrawing = bopc.orderedPartConfig.orderedPartConfigOrderedAttachmentTeasers?.find(
        orderedAttachment => orderedAttachment.attachmentTypeKey === 'blanked_technical_drawing' && orderedAttachment.languageKey === 'en');

      if (!!germanDrawing && !!englishDrawing && acc) {
        return acc;
      }

      return !acc;
    }, true);

    this.setState({bundle, allDrawingsUploaded});

  };

  async componentDidMount() {
    await this.getBundleAndSaveInState();
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState?.bundle?.id !== this.state.bundle.id) {
      await this.getBundleAndSaveInState();
    }
  }

  onDeleteBundleOrderedPartConfig = async (bundleId, orderedPartConfigId) => {
    await deleteBundleOrderedPartConfig(bundleId, orderedPartConfigId);
    this.setState({
      bundle: {
        ...this.state.bundle,
        bundleOrderedPartConfigs: this.state.bundle.bundleOrderedPartConfigs.filter(bopc => bopc.orderedPartConfigId !== orderedPartConfigId),
      },
    });
  };

  render() {
    const prevPath = this.props.pathHistory[this.props.pathHistory.length - 2];

    const cogs = this.state.bundle?.bundleOrderedPartConfigs.reduce((acc, bopc) => acc + (parseFloat(bopc.pricePerPiece) * bopc.orderedPartConfig.batchSize), 0);
    const logisticCosts = this.state.bundle?.bundleOrderedPartConfigs.reduce(
      (acc, bopc) => acc + bopc.orderedPartConfig.orderedPart.orderedPartPartFeatures.find(oppf => oppf.partFeatureKey === 'weight')?.value / 1000 * bopc.orderedPartConfig.batchSize * 10, 0);
    const customerTotal = this.state.bundle?.bundleOrderedPartConfigs.reduce((acc, bopc) => acc + parseFloat(bopc.orderedPartConfigTeaser.totalCost), 0);

    const cm1 = ((customerTotal - cogs) / customerTotal * 100).toFixed(2);
    const cm1WithLogistics = ((customerTotal - cogs - logisticCosts) / customerTotal * 100).toFixed(2);

    const isOrdered = ['ordered', 'delayed', 'shipped', 'partially_delivered', 'delivered'].includes(this.state.bundle?.statusKey);

    return !this.state.bundle ? <div>Loading...</div> : (
      <Grid container alignContent={'flex-start'}>
        <Grid item container>
          <Grid item xs={12} marginTop={'20px'}>
            <Breadcrumbs aria-label={'breadcrumbs'}>

              <Link
                component={'button'}
                onClick={() => this.props.navigate(`/orders/${this.state.bundle.bundleOrderedPartConfigs[0]?.orderedPartConfig.orderedPartListId}`)}
                variant={'subtitle2'}
                underline={'hover'}
                sx={{color: '#b8b8b8'}}
              >Order KMS-{this.state.bundle.bundleOrderedPartConfigs[0]?.orderedPartConfig.orderedPartListId}
              </Link>
              {prevPath?.includes('parts') &&
                <Link
                  component={'button'}
                  onClick={() => this.props.navigate(`/parts/${prevPath.split('/')[2]}`)}
                  variant={'subtitle2'}
                  underline={'hover'}
                  sx={{color: '#b8b8b8'}}
                >Part {prevPath.split('/')[2]}
                </Link>
              }
              <Link variant={'subtitle2'} underline={'hover'} sx={{color: '#b8b8b8'}}>Bundle Details</Link>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={6} marginBottom={'15px'}>
            <Typography variant={'h2'}>Bundle </Typography>
          </Grid>
          <Grid item xs={6} marginBottom={'15px'} display={'flex'} justifyContent={'flex-end'} spacing={5}>
            {this.state.bundle.statusKey === 'rejected' &&
              <Grid item marginX={1}>
                <ConfirmModal
                  disabled={false}
                  buttonText={'Request Counter Offer'}
                  onSubmit={async () => {
                    await updateBundleStatus(this.state.bundle.id, 'counter_offer_requested', []);
                    await this.getBundleAndSaveInState();
                  }}
                  title={'Are you sure you want to request the counter offer?'}
                  successMessage={'You successfully requested a counter offer'}
                  color={'warning'}
                />
              </Grid>
            }

            {this.state.bundle.statusKey === 'counter_offer_submitted' &&
              <Grid item marginX={1}>
                <ConfirmModal
                  disabled={false}
                  buttonText={'Accept Counter Offer'}
                  onSubmit={async () => {
                    await updateBundleStatus(this.state.bundle.id, 'counter_offer_accepted', []);
                    await this.getBundleAndSaveInState();
                  }}
                  title={'Are you sure you want to accept the counter offer?'}
                  successMessage={'You successfully accepted the counter offer'}
                  color={'error'}
                />
              </Grid>
            }

            {this.state.bundle.statusKey === 'new' &&
              <>
                <Grid item marginX={1}>
                  <ConfirmModal
                    tooltip={!this.state.allDrawingsUploaded ? 'Please make sure that all blanked drawings are uploaded' : ''}
                    disabled={!this.state.allDrawingsUploaded}
                    buttonText={'Release to Kaktus'}
                    onSubmit={async () => {
                      await releaseMultipleBundles([this.state.bundle.id]);
                    }}
                    onClose={this.getBundleAndSaveInState}
                    title={'Are you sure you want to release the bundle to kaktus?'}
                    successMessage={'You successfully released this bundle'}
                    color={'success'}
                  />
                </Grid>
                <Grid item marginX={1}>
                  <ConfirmModal
                    disabled={false}
                    buttonText={'Delete this bundle'}
                    onSubmit={async () => {
                      await deleteBundle(this.state.bundle.id);
                      this.props.navigate('/');
                    }}
                    onClose={() => this.getBundleAndSaveInState()}
                    title={'Are you sure you want to delete this bundle?'}
                    successMessage={'You successfully deleted this bundle'}
                    color={'error'}
                  />
                </Grid>
              </>
            }
            {!['new', 'open', 'lost', 'cancelled'].includes(this.state.bundle.statusKey) &&
              <Grid item marginX={1}>
                <ConfirmModal
                  disabled={false}
                  buttonText={'Cancel this bundle'}
                  onSubmit={async () => {
                    await updateBundleStatus(this.state.bundle.id, 'cancelled', []);
                    await this.getBundleAndSaveInState();
                  }}
                  title={'Are you sure you want to cancel this bundle?'}
                  successMessage={'You successfully cancelled this bundle'}
                  color={'error'}
                />
              </Grid>
            }

          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={12} marginBottom={5}>
            <Paper sx={{height: '100%', backgroundColor: '#F3F3F3', borderStyle: 'solid', border: 1, boxShadow: 'none'}}>
              <Grid item container xs={12} paddingX={4} paddingY={'15px'} columns={isOrdered ? 6 : 5}>

                <Grid item xs={1} display={'grid'} justifyContent={'center'}>
                  <Grid item xs={12}>
                    <Typography variant={'h6'}>{this.state.bundle.statusKey}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant={'subtitle2'}>
                      Status
                      <IconButton size={'small'} onClick={() => this.setState({collapse: !this.state.collapse})}>
                        {this.state.collapse ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                      </IconButton>
                    </Typography>
                  </Grid>
                </Grid>

                <Grid item xs={1} display={'grid'} justifyContent={'center'}>
                  <Grid item xs={12}>
                    <Typography variant={'h6'}>{this.state.bundle.companyName}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant={'subtitle2'}>
                      Supplier Name
                    </Typography>
                  </Grid>
                </Grid>

                <Grid item xs={1} display={'grid'} justifyContent={'center'}>
                  <Grid item xs={12}>
                    <Typography variant={'h6'}>{this.state.bundle.bundleKid}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant={'subtitle2'}>Bundle KID</Typography>
                  </Grid>
                </Grid>


                <Grid item xs={1} display={'grid'} justifyContent={'center'}>
                  <Grid item xs={12} display={'flex'} justifyContent={'space-between'}>
                    <Typography variant={'h6'}>{toDisplayDate(this.state.bundle.deliveryDate)}</Typography>
                    <Typography variant={'h6'} color={'error'}>&nbsp;{!!this.state.bundle.counterOfferDate && `-> ${toDisplayDate(this.state.bundle.counterOfferDate)}`}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant={'subtitle2'}>Delivery Date {!!this.state.bundle.counterOfferDate && ' / Counter Offer'}</Typography>
                  </Grid>
                </Grid>
                {isOrdered &&
                  <Grid item xs={1} display={'grid'} justifyContent={'center'}>
                    <Grid item xs={12}>
                      <Typography variant={'h6'}>{`${cm1}% / ${cm1WithLogistics}%`} </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant={'subtitle2'}>CM1 / with Logistics</Typography>
                    </Grid>
                  </Grid>
                }

                <Grid item xs={1} display={'grid'} justifyContent={'center'}>
                  <Grid item xs={12} display={'flex'}>
                    <Typography variant={'h6'}>&nbsp; {toDisplayCurrency(this.state.bundle.price) || '-'} </Typography>
                    <Typography variant={'h6'} color={'error'}>&nbsp; {!!this.state.bundle.counterOfferPrice && `-> ${toDisplayCurrency(this.state.bundle.counterOfferPrice)}`}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant={'subtitle2'}>Total Price {!!this.state.bundle.counterOfferPrice && ' / Counter Offer'}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Collapse in={this.state.collapse} timeout="auto" unmountOnExit>
                <Grid container display={'flex'} justifyContent={'center'}>
                  <Grid item xs={12} paddingBottom={0}>
                    <Table>
                      <TableHead sx={{backgroundColor: '#D1D5DB'}}>
                        <TableCell width={'450vw'}>Status Key</TableCell>
                        <TableCell width={'550vw'}>Status Features</TableCell>
                        <TableCell>Comment</TableCell>
                        <TableCell>Created At</TableCell>
                        <TableCell>Updated by</TableCell>
                      </TableHead>
                      <TableBody>
                        {this.state.bundle?.bundleStatuses.sort((a, b) => b.id - a.id).map(bs => {
                          const {firstName, lastName} = bs.user || {};
                          return (
                            <TableRow>
                              <TableCell>{bs.statusKey}</TableCell>
                              <TableCell>
                                <ul>
                                  {bs.bundleStatusFeatureValues?.filter(bsfv => bsfv.statusFeatureKey !== 'comment')?.map(bsfv => <li>{`${bsfv.statusFeatureKey}: ${bsfv.value}`}</li>)}
                                </ul>
                              </TableCell>
                              <TableCell>
                                <div style={{whiteSpace: 'pre-wrap'}}>
                                  {bs.bundleStatusFeatureValues?.find(bsfv => bsfv.statusFeatureKey === 'comment')?.value}
                                </div>
                              </TableCell>
                              <TableCell>{toDisplayDate(bs.createdAt)}</TableCell>
                              <TableCell>{firstName && lastName ? `${firstName} ${lastName}` : bs.user?.email}</TableCell>
                            </TableRow>
                          );
                        })}

                      </TableBody>
                    </Table>
                  </Grid>
                </Grid>
              </Collapse>
            </Paper>


          </Grid>
          <Grid item container xs={12} justifyContent={'right'}>
            {this.state.bundle.statusKey === 'new' &&
              <AddPartToBundleModal
                bundle={this.state.bundle}
                onClose={this.getBundleAndSaveInState}
              />
            }
          </Grid>
          <Grid item container xs={12} spacing={1}>
            {!this.state.bundle ?
              'Loading...' :
              this.state.bundle.bundleOrderedPartConfigs.map(bopc =>
                <CollapsablePartDetails
                  orderedPartConfig={bopc.orderedPartConfig}
                  buttons={<Box display={'flex'} alignItems={'center'}>
                    {!!bopc.counterOfferPrice && <Chip sx={{marginRight: 10}} label={`Counter Offer Price: ${toDisplayCurrency(bopc.counterOfferPrice)}`} />}

                    {this.state.bundle.statusKey === 'new' ?
                      <>
                        <TextField
                          defaultValue={bopc.targetCost}
                          onBlur={async (e) => {
                            await updateTargetCost(this.state.bundle.id, bopc.id, e.target.value);
                            await this.getBundleAndSaveInState();
                          }}
                          sx={{width: '140px', marginRight: '20px'}}
                          size={'small'}
                          label={'Target Cost'}
                          type={'number'}
                        />
                        <ConfirmModal
                          title={'Are you sure that you want to delete this Part?'}
                          iconButtonIcon={<RemoveCircle color={'error'} fontSize={'large'} />}
                          onSubmit={() => this.onDeleteBundleOrderedPartConfig(this.state.bundle.id, bopc.orderedPartConfig.id)}
                          successMessage={'Part has been deleted.'}
                        />
                      </> : <></>
                    }

                    <RouterLink to={`/parts/${bopc.orderedPartConfig.id}`}>
                      <IconButton>
                        <ArrowCircleRight fontSize={'large'} color={'primary'} />
                      </IconButton>
                    </RouterLink>
                  </Box>}
                />)}
          </Grid>
        </Grid>
      </Grid>
    );
  }
}
