import {Box, Button, Paper} from '@mui/material';
import {DataGridPro} from '@mui/x-data-grid-pro';
import React, {useEffect, useState} from 'react';
import {UploadBlankedDrawingsModal} from '../Modals/UploadBlankedDrawingsModal/UploadBlankedDrawingsModal';
import {downloadOrderedPartConfigFiles, getOrderedPartConfigsWithoutKSDsInBothLanguages} from '../../services/knest';
import {Download} from '@mui/icons-material';

type Props = {
  navigate: Function
}

export const BlankingBacklogTable = (props: Props) => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);

  const getMissingDrawingLanguage = (orderedPartConfig) => {
    const opcoa = orderedPartConfig.orderedPartConfigOrderedAttachmentTeasers;
    if (!(opcoa.some(x => x.languageKey === 'en') || opcoa.some(x => x.languageKey === 'de'))) {
      return 'German / English';
    }
    if (opcoa.some(x => x.languageKey === 'en')) {
      return 'German';
    }
    return 'English';
  };

  const datagridColumns = [
    {field: 'id', headerName: 'ID', hide: true},
    {
      field: 'orderedPartListId',
      flex: 1,
      headerName: 'Order ID',
      valueGetter: (params) => `KMS-${params.value}`,
      renderCell: params => <Button sx={{margin: 1}} fullWidth size={'small'} variant={'outlined'} onClick={() => props.navigate(`/orders/${params.row.orderedPartListId}`)}>{params.value}</Button>,
    },
    {field: 'partName', valueGetter: (params) => params.row.orderedPart.name, headerName: 'Part Name', flex: 2.5},
    {field: 'Missing Drawings', valueGetter: (params) => getMissingDrawingLanguage(params.row), headerName: 'Missing Drawings', flex: 2},
    {
      field: 'MA Task ID',
      valueGetter: (params) => params.row.originalPartConfig?.batchSizes.find(x => x.batchSize === params.row.batchSize)?.maTaskId,
      headerName: 'MA Task ID',
      flex: 1,
      renderCell: params => params.value,
    },

    {
      field: 'actions', headerName: '', width: 400, align: 'right',
      renderCell: (params) =>
        <>
          <Button
            onClick={() => downloadOrderedPartConfigFiles(params.row.id)} variant={'outlined'}
            startIcon={<Download fontSize={'large'} />}
            size={'small'}
            sx={{marginRight: '5px'}}
          >
            Download Files
          </Button>
          <UploadBlankedDrawingsModal orderedPartConfig={params.row} updateState={console.log} buttonSize={'small'} />
        </>,
    },
  ];

  useEffect(() => {
    setLoading(true);
    getOrderedPartConfigsWithoutKSDsInBothLanguages()
      .then(res => {
        setRows(res as any);
        setLoading(false);
      });
  }, []);

  return (
    <Paper sx={{height: '100%'}}>
      <Box sx={{height: '100%'}}>
        <DataGridPro
          rows={rows}
          columns={datagridColumns as any}
          loading={loading}
          initialState={{
            sorting: {
              sortModel: [{field: 'id', sort: 'asc'}],
            },
          }}
        />
      </Box>
    </Paper>
  );
};
