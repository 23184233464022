import Cookies from 'universal-cookie/lib';
import jwt from 'jwt-simple';
import {getKooperUser} from './knest';
import Axios from 'axios';

const cookies = new Cookies();

const kauthCookieName = `${process.env.REACT_APP_ENVIRONMENT}_kauth`;

export const getUserInfo = () => {
  try {
    return getKooperUser();
  } catch (e) {
    console.error(e);
  }
};

export const getToken = (returnObject = false) => {
  const authCookie = cookies.get(kauthCookieName);
  if (!authCookie) {
    return false;
  }

  const authObject = jwt.decode(authCookie, 'secret');
  return returnObject ? authObject : `Bearer ${authObject.access_token}`;
};

export const logout = () => {
  window.location = `${process.env.REACT_APP_KAUTH_URL}/logout?goto=${window.location.href}`;
};

export const loginAndRedirect = () => {
  window.location = `${process.env.REACT_APP_KAUTH_URL}/login?goto=${window.location.href}`;
};

export const handleUnauthorized = async () => {
  const token = getToken(true);
  
  if (!token?.refresh_token) {
    loginAndRedirect();
  }

  try {
    const {data} = await Axios.post(`${process.env.REACT_APP_KOOPER_AUTH_URL}/oauth/token`, {
      client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
      client_secret: process.env.REACT_APP_AUTH_CLIENT_SECRET,
      refresh_token: token.refresh_token,
      grant_type: 'refresh_token',
    });

    const encodedToken = jwt.encode(data, 'secret');
    const domain = process.env.REACT_APP_ENVIRONMENT === 'local' ? 'localhost' : '.kreatize.com';
    cookies.set(kauthCookieName, encodedToken, {domain, maxAge: 604800, path: '/'});
  } catch (error) {
    loginAndRedirect();
  }
};
