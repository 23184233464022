import {Grid, Typography} from '@mui/material';
import React from 'react';
import {BlankingBacklogTable} from '../components/BlankingBacklogTable/BlankingBacklogTable';

export const BlankingBacklog = (props) => {
  return (
    <Grid container alignContent={'flex-start'} sx={{height: '100%'}}>
      <Grid item xs={12} marginTop={'20px'}>
        <Typography variant={'subtitle2'} sx={{color: '#b8b8b8'}}>All Parts with missing drawings</Typography>
      </Grid>
      <Grid item xs={12} marginBottom={'15px'}>
        <Typography variant={'h2'}>Parts to be Blanked</Typography>
      </Grid>
      <Grid item xs={12} height={'87vh'}>
        <BlankingBacklogTable {...props} />
      </Grid>
    </Grid>
  );
};
