import React, {Component} from 'reactn';
import {Box, Chip, Grid, IconButton, List, ListItem, Paper, Tab, Tooltip, Typography} from '@mui/material';
import {deleteBundle, getOrder, getOrderedPartConfigs, releaseMultipleBundles} from '../../services/knest';
import {toDisplayCurrency, toDisplayDate} from '../../utils/formatters';
import _ from 'lodash';
import {ArrowCircleRight, Delete} from '@mui/icons-material';
import {DataGridPro} from '@mui/x-data-grid-pro';
import {TabList, TabPanel, TabContext} from '@mui/lab';
import {getColorForStatus} from '../../utils/colormapping';
import {CreateBundlesModal} from '../Modals/CreateBundlesModal/CreateBundlesModal';
import {ConfirmModal} from '../Modals/ConfirmModal/ConfirmModal';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import {Link} from 'react-router-dom';

TimeAgo.addLocale(en);

const timeAgo = new TimeAgo('en-US');

type Props = {
  navigate: Function,
  history: any
}
type State = {
  order: {
    id: number
    orderedPartConfigs: any[],
    kmsId: string
    statusKey: string
    desiredDeliveryDate: string
    companyName: string,
    createdAt: Date
  } | null,
  bundles: any[],
  selectedTab: 'parts' | 'bundles',
  loading: boolean,
  dataGridLoading: boolean,
  allDrawingsUploaded: boolean,
  orderedPartConfigs: any[]
}

export class OrderDetails extends Component <Props, State> {
  constructor() {
    super();
    this.state = {
      order: null,
      orderedPartConfigs: [],
      bundles: [],
      selectedTab: 'parts',
      loading: true,
      allDrawingsUploaded: false,
      dataGridLoading: true,
    };
  }

  getOrderAndSaveInState = async () => {
    this.setState({loading: true});
    const orderId = window.location.pathname.split('/').pop() as any;
    const order = await getOrder(orderId);

    this.setState({order, loading: false});

    const orderedPartConfigs = await getOrderedPartConfigs({where: {orderedPartListId: parseInt(orderId)}});
    const bundles = _.uniqBy(orderedPartConfigs.map(opc => opc.bundleOrderedPartConfigTeasers.map(bopc => bopc.bundle)).flat(), 'id');

    const allDrawingsUploaded = orderedPartConfigs.every(opc =>
      opc.orderedPartConfigOrderedAttachmentTeasers.find(oa => oa.attachmentTypeKey === 'blanked_technical_drawing' && oa.languageKey === 'de') &&
      opc.orderedPartConfigOrderedAttachmentTeasers.find(oa => oa.attachmentTypeKey === 'blanked_technical_drawing' && oa.languageKey === 'en'),
    );

    this.setState({orderedPartConfigs, bundles, allDrawingsUploaded, dataGridLoading: false});
  };

  async componentDidMount() {
    await this.getOrderAndSaveInState();
  }

  createReorderChip = (row) => {
    const listOfFormattedReorders = row.orderedPartConfigReorderTeasers.map(order => {
      const orderedOn = `Ordered on ${toDisplayDate(order.bundleOrderedPartConfigTeaser.orderedPartConfigTeaser.createdAt)}`;
      const fromOrder = `from order KMS-${order.bundleOrderedPartConfigTeaser.orderedPartConfigTeaser.orderedPartListId}`;
      const placedWith = `Placed with ${order.supplier.companyName}`;
      const pricePerPiece = order.bundleOrderedPartConfigTeaser.orderedPartConfigTeaser.totalCostPerUnit;
      const supplierPricePerPiece = order.bundleOrderedPartConfigTeaser.pricePerPiece;
      const counterOfferPricePerPiece = order.bundleOrderedPartConfigTeaser.counterOfferPrice;
      const margin = ((pricePerPiece - (counterOfferPricePerPiece || supplierPricePerPiece)) / pricePerPiece * 100).toFixed(2);
      const placedPrice = `${counterOfferPricePerPiece ? '(with counteroffer)' : ''} at ${toDisplayCurrency(counterOfferPricePerPiece || supplierPricePerPiece)}/piece`;
      const placedInBundle = `(in bundle KAKTUS-${order.bundleId})`;
      const marginOf = `margin of ${margin}%`;
      const customerPrice = `(customer price: ${toDisplayCurrency(pricePerPiece)}/piece)`;

      return `${orderedOn} ${fromOrder}, ${placedWith} ${placedPrice} ${placedInBundle}, ${marginOf} ${customerPrice}`;
    });
    return <Tooltip
      arrow
      title={<List>
        {listOfFormattedReorders.map(item => <ListItem>‣ {item}</ListItem>)}
      </List>}
    >
      <Chip sx={{margin: 1}} label={'Reorder ℹ️'} color={'error'} />
    </Tooltip>;
  };

  render() {
    const orderedStatuses = ['ordered', 'delayed', 'shipped', 'partially_delivered', 'delivered'];
    const orderedPartConfigs = this.state.orderedPartConfigs.map(opc => ({
      ...opc,
      bundleOrderedPartConfigTeaser: opc.bundleOrderedPartConfigTeasers.find(bopc => orderedStatuses.includes(bopc.bundle.statusKey)),
    }));

    const cogs = orderedPartConfigs.reduce((acc, opc) => acc + (parseFloat(opc.bundleOrderedPartConfigTeaser?.pricePerPiece) * opc.batchSize), 0);
    const logisticCosts = orderedPartConfigs.reduce((acc, opc) => acc + opc.orderedPart.orderedPartPartFeatures.find(oppf => oppf.partFeatureKey === 'weight')?.value / 1000 * opc.batchSize * 10, 0);
    const customerTotal = orderedPartConfigs.reduce((acc, opc) => acc + parseFloat(opc.totalCost), 0);
    const cm1 = ((customerTotal - cogs) / customerTotal * 100);
    const cm1WithLogistics = ((customerTotal - cogs - logisticCosts) / customerTotal * 100);

    return !this.state.order ? <div>Loading...</div> : (
      <Grid container alignContent={'flex-start'}>
        <Grid item container>
          <Grid item xs={12} marginTop={'20px'}>
            <Typography variant={'subtitle2'} sx={{color: '#b8b8b8'}}>Detailed Information</Typography>
          </Grid>
          <Grid item xs={12} marginBottom={'15px'}>
            <Typography variant={'h2'}>Order </Typography>
          </Grid>
        </Grid>
        <Grid item container spacing={2}>

          <Grid item xs={12} marginBottom={1}>
            <Paper sx={{height: '100%', backgroundColor: '#F3F3F3', borderStyle: 'solid', border: 1, boxShadow: 'none'}}>
              <Grid item container xs={12} paddingX={4} paddingY={'15px'} columns={6}>
                <Grid item xs={1} display={'grid'} justifyContent={'center'}>
                  <Grid item xs={12}>
                    <Typography variant={'h6'}>{this.state.order.kmsId}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant={'subtitle2'}>Order Id</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={1} display={'grid'} justifyContent={'center'}>
                  <Grid item xs={12}>
                    <Typography variant={'h6'}>{this.state.order.companyName}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant={'subtitle2'}>Customer Name</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={1} display={'grid'} justifyContent={'center'}>
                  <Grid item xs={12}>
                    <Typography variant={'h6'}>{this.state.order.statusKey}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant={'subtitle2'}>Status</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={1} display={'grid'} justifyContent={'center'}>
                  <Grid item xs={12}>
                    <Typography variant={'h6'}>{toDisplayDate(this.state.order.desiredDeliveryDate)}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant={'subtitle2'}>Delivery Date</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={1} display={'grid'} justifyContent={'center'}>
                  <Grid item xs={12}>
                    <Typography variant={'h6'}>{`${Number.isNaN(cm1) ? '-' : cm1.toFixed(2)}% / ${Number.isNaN(cm1WithLogistics) ? '-' : cm1WithLogistics.toFixed(2)}%`} </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant={'subtitle2'}>CM1 / with Logistics</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={1} display={'grid'} justifyContent={'center'}>
                  <Grid item xs={12}>
                    <Typography variant={'h6'}>{timeAgo.format(new Date(this.state.order.createdAt))}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant={'subtitle2'}>Created</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={12} height={'200px'}>

            <TabContext value={this.state.selectedTab}>
              <Box>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TabList onChange={(e, value) => this.setState({selectedTab: value})} aria-label="lab API tabs example">
                      <Tab label={`Parts  (${this.state.orderedPartConfigs.length})`} value="parts" />
                      <Tab label={`Bundles  (${this.state.bundles.length})`} value="bundles" />
                    </TabList>
                  </Grid>
                  {this.state.selectedTab === 'bundles' && (this.state.order.statusKey !== 'placed' || this.state.orderedPartConfigs.some(opc => opc.bundleOrderedPartConfigTeasers.some(bopct => bopct.isInComplaint))) &&
                    <Grid item container xs={6} display={'flex'} justifyContent={'flex-end'} alignItems={'center'} spacing={2}>
                      <Grid item>
                        <CreateBundlesModal order={this.state.order} orderedPartConfigs={this.state.orderedPartConfigs} onClose={() => this.getOrderAndSaveInState()} />
                      </Grid>
                      <Grid item>
                        <ConfirmModal
                          tooltip={!this.state.allDrawingsUploaded ? 'Please make sure that all blanked drawings are uploaded' : ''}
                          disabled={!this.state.allDrawingsUploaded}
                          buttonText={'Release to Kaktus'}
                          onSubmit={async () => {
                            await releaseMultipleBundles(this.state.bundles.map(x => x.id));
                          }}
                          onClose={() => this.getOrderAndSaveInState()}
                          title={'Are you sure you want to release all bundles to kaktus?'}
                          successMessage={'You successfully released all bundles'}
                          color={'success'}
                        />
                      </Grid>
                    </Grid>}
                </Grid>
              </Box>
              <TabPanel value="parts">
                <Grid item xs={12} sx={{height: 'calc(100vh - 285px)'}}>
                  <DataGridPro
                    loading={this.state.dataGridLoading}
                    hideFooter
                    onRowClick={(params, event) => event.metaKey || event.ctrlKey || event.shiftKey ? window.open(`/parts/${params.row.id}`, '_blank') : this.props.navigate(`/parts/${params.row.id}`)}
                    columns={[
                      {field: 'orderedPart', headerName: 'Part Name', flex: 1, valueGetter: ({value}) => value.name},
                      {
                        headerName: 'Statuses',
                        field: 'bundleOrderedPartConfigTeasers',
                        flex: 5,
                        valueGetter: ({value}) => {
                          return Object.entries(_.countBy(value, (v) => v.bundle.statusKey)).map(([statusKey]) => statusKey).join(',');
                        },
                        renderCell: (params) =>
                          <Grid overflow={'scroll'}>
                            {Object.entries(_.countBy(params.row.bundleOrderedPartConfigTeasers, (v) => v.bundle.statusKey)).map(([statusKey, count]) =>
                              <Chip
                                color={getColorForStatus(statusKey)}
                                sx={{marginRight: '4px'}}
                                label={`${statusKey} (${count})`}
                              />,
                            )}
                          </Grid>,
                      },
                      {
                        headerName: '',
                        field: 'doesntMatter',
                        width: 450,
                        renderCell: ({row}) => <>
                          {row.isReorder ? this.createReorderChip(row) : <></>}
                          {row.isHighWeight ? <Chip sx={{margin: 1}} label={'High Weight (>80kg)'} color={'error'} /> : <></>}
                          {row.isHighDimension ? <Chip sx={{margin: 1}} label={'High Dimension'} color={'error'} /> : <></>}
                          {row.drawingsMissing ? <Chip sx={{margin: 1}} label={'Blanked Drawing(s) Missing'} color={'error'} /> : <></>}
                        </>
                        ,
                      },
                      {
                        field: 'action',
                        headerName: '',
                        type: 'actions',
                        flex: 0.2, align: 'center',
                        renderCell: (params) =>
                          <Link to={`/parts/${params.row.id}`}>
                            <IconButton size={'small'}><ArrowCircleRight color={'primary'} /></IconButton>
                          </Link>,
                      },
                    ]}
                    rows={this.state.orderedPartConfigs}
                  />
                </Grid>
              </TabPanel>

              <TabPanel value="bundles">
                <Grid item xs={12} sx={{height: 'calc(100vh - 285px)'}}>
                  <DataGridPro
                    loading={this.state.dataGridLoading}
                    hideFooter
                    initialState={{
                      sorting: {
                        sortModel: [{field: 'id', sort: 'asc'}],
                      },
                    }}
                    columns={[
                      {field: 'id', headerName: '', flex: 0.5, hide: true},
                      {field: 'bundleKid', headerName: 'KAKTUS ID', flex: 0.5},
                      {
                        field: 'statusKey',
                        headerName: 'Status',
                        flex: 1,
                        renderCell: ({value}) => <Chip color={getColorForStatus(value)} label={value} />,
                      },
                      {field: 'companyName', headerName: 'Supplier', flex: 1},
                      {field: 'numberOfPartConfigs', headerName: '# of Parts', flex: 1},
                      {
                        field: 'action',
                        headerName: '',
                        flex: 0.4,
                        type: 'actions',
                        align: 'right',
                        renderCell: (params) =>
                          <Grid container justifyContent={'flex-end'}>
                            <Grid item>
                              {params.row.statusKey === 'new' &&
                                <ConfirmModal
                                  title={'Are you sure that you want to delete this Bundle?'}
                                  iconButtonIcon={<Delete color={'error'} />}
                                  onSubmit={() => deleteBundle(params.row.id).then(async () => await this.getOrderAndSaveInState())}
                                />
                              }
                            </Grid>
                            <Grid item>
                              <Link to={`/bundles/${params.row.id}`}>
                                <IconButton size={'small'}><ArrowCircleRight color={'primary'} /></IconButton>
                              </Link>
                            </Grid>
                          </Grid>,
                      },
                    ]}
                    rows={this.state.bundles}
                    onRowClick={(params, event) => event.metaKey || event.ctrlKey || event.shiftKey ? window.open(`/bundles/${params.row.id}`, '_blank') : this.props.navigate(`/bundles/${params.row.id}`)}
                  />
                </Grid>
              </TabPanel>
            </TabContext>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}
