import React from 'react';
import Sidebar from './AppContainers/Sidebar/Sidebar';
import MainContainer from './AppContainers/MainContainer/MainContainer';
import './AppLayout.scss';
import {Toaster} from 'react-hot-toast';
import {useMediaQuery, useTheme} from '@mui/material';


const AppLayout = (props) => {

  const [toggled, setToggled] = React.useState(false);
  const [collapsed, setCollapsed] = React.useState(true);

  const handleToggle = (value) => {
    setToggled(value);
    setCollapsed(!value);
  };

  const useWidth = () => {
    const theme = useTheme();
    const keys = [...theme.breakpoints.keys].reverse();
    return (
      keys.reduce((output, key) => {
        const matches = useMediaQuery(theme.breakpoints.up(key));
        return !output && matches ? key : output;
      }, null) || 'xs'
    );
  };

  return (
    <React.Fragment>
      <Toaster
        position='bottom-center'
        containerStyle={{
          zIndex: 99999,
        }}
        toastOptions={{
          style: {
            zIndex: 99999,
            padding: '16px',
            maxWidth: '800px',
          },
        }}
      />


      <div className={`app ${toggled ? 'toggled' : ''} ${collapsed ? 'collapsed' : ''}`}>
        <Sidebar {...props} collapsed={collapsed} toggled={toggled} handleSidebarToggle={handleToggle} handleSidebarCollapsed={setCollapsed} />
        <MainContainer {...props} style={{marginLeft: ['xs', 'sm'].includes(useWidth()) ? 0 : 80}} />
      </div>
    </React.Fragment>
  );
};

export default AppLayout;
