export const statuses = {
  new: 'New',

  open: 'Open',
  lost: 'Lost',
  rejected: 'Rejected',

  counter_offer_requested: 'Counteroffer Requested',
  counter_offer_submitted: 'Counteroffer Submitted',
  counter_offer_accepted: 'Counteroffer Approved',

  ordered: 'Ordered',
  delayed: 'Delayed',
  shipped: 'Shipped',
  delivered: 'Delivery Confirmed',
  partially_delivered: 'Partially Delivered',


  complaint: 'Complaint',
  cancelled: 'Cancelled',
};