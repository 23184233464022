import React from 'react';
import noPreview from '../../assets/img/no-preview.jpg';
import {openPdf} from '../../services/knest';
import STLViewer from 'kad-viewer';
import SwitchSelector from 'react-switch-selector';
import {Grid} from '@mui/material';


const Thumbnail = (props) => {
  const maxHeight = props.maxHeight ? props.maxHeight : '300px';

  const setThumbnailToNoPreviewAvailable = id => {
    document.getElementById(id).src = noPreview;
  };

  const [type, setType] = React.useState('drawing');
  const [stlFile, setStlFile] = React.useState(Buffer.from('1'));

  const selectorOptions = [
    {
      label: <div style={{marginTop: 7, color: type === 'drawing' ? 'white' : ''}}>2D</div>,
      value: 'drawing',
    },
    {
      label: <div style={{marginTop: 7, color: type === 'stl' ? 'white' : ''}}>3D</div>,
      value: 'stl',
    },
  ];

  return (
    <Grid item container spacing={2}>

      <Grid item xs={12}>
        <div style={{
          maxHeight,
          width: '300px',
          height: '300px',
          objectFit: 'contain',
          border: '1px solid #D1D5DB',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        >
          {type === 'stl' ?
            <STLViewer
              rotate={true}
              orbitControls={true}
              model={stlFile}
              rotationSpeeds={[0, 0.01, 0]}
              modelColor={'#32bee1'}
              backgroundColor={'#FFF'}
              height={250}
              width={250}
            />
            :

            <img
              id={props.id}
              alt='Attachment preview'
              src={props.src}
              className={props.className}
              style={{
                maxHeight,
                width: '250px',
                height: '250px',
                objectFit: 'contain',
                border: 0,
              }}
              onError={() => setThumbnailToNoPreviewAvailable(props.id)}
              onClick={() => props.numberOfBlankedDrawings >= 1 && openPdf(props.data.id, props.partConfigId)}
            />
          }
        </div>
      </Grid>

      <Grid item xs={12}>
        <div className='switch-wrapper' style={{width: '300px', height: 30}}>
          <SwitchSelector
            onChange={(value) => {
              setType(value);
            }}
            options={selectorOptions}
            initialSelectedIndex={0}
            selectedBackgroundColor={'#32bee1'}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default Thumbnail;