import React from 'react';
import {Grid, Typography} from '@mui/material';
import {OrdersTable} from 'components/OrdersTable/OrdersTable';

const Orders = (props: any) => {
  return (
    <Grid container alignContent={'flex-start'} sx={{height: '100%'}}>
      <Grid item xs={12} marginTop={'20px'}>
        <Typography variant={'subtitle2'} sx={{color: '#b8b8b8'}}>All Orders</Typography>
      </Grid>
      <Grid item xs={12} marginBottom={'15px'}>
        <Typography variant={'h2'}>Orders</Typography>
      </Grid>
      <Grid item xs={12} height={'87vh'}>
        <OrdersTable {...props} />
      </Grid>
    </Grid>
  );
};

export default Orders;
