import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import {getToken} from 'services/auth';

const constructLoadPath = (lng, ns) => {
  if (['processes', 'processFeatures'].includes(ns[0])) {
    const mapping = {
      processes: 'process',
      processFeatures: 'process_feature',
    };
    const formattedToken = getToken().substring(7); // Remove "Bearer " from the token
    return `${process.env.REACT_APP_KNEST_KUPPLIAS_URL}/translations/formatted?languageKey=${lng[0]}&category=${mapping[ns[0]]}&access_token=${formattedToken}`;
  } else {
    return `/locales/${lng[0]}/${ns[0]}.json`;
  }
};

const i18nOptions = {
  lng: localStorage.getItem('lang') || 'de',
  fallbackLng: 'de',
  ns: [
    'common',
    'processes',
    'processFeatures',
  ],
  backend: {
    loadPath: constructLoadPath,
    lng: localStorage.getItem('lang') || 'de',
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(HttpApi)
  .init(i18nOptions);

i18n.on('languageChanged', (lng) => {
  localStorage.setItem('lang', lng);
});

export default i18n;
