import {Box, IconButton, Paper} from '@mui/material';
import {DataGridPro} from '@mui/x-data-grid-pro';
import React, {Component} from 'react';
import {getOrders} from '../../services/knest';
import {toDisplayDate} from '../../utils/formatters';
import {ArrowCircleRight} from '@mui/icons-material';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import {Link} from 'react-router-dom';

TimeAgo.addLocale(en);
const timeAgo = new TimeAgo('en-US');

type Props = {
  navigate: Function
}
type State = {
  columns: any[],
  rows: any[],
  loading: boolean,
}

export class OrdersTable extends Component <Props, State> {

  constructor(props) {
    super(props);

    this.state = {
      columns: [
        {field: 'id', headerName: 'Order ID', flex: 1.5, valueFormatter: ({value}) => `KMS-${value}`},
        {field: 'companyName', headerName: 'Customer Name', flex: 2.00},
        {field: 'name', headerName: 'Order Name', flex: 2},
        {field: 'statusKey', headerName: 'Status', flex: 1.50},
        {field: 'orderedPartConfigs', headerName: '# of Parts', flex: 1, valueGetter: ({value}) => value.length},
        {
          field: 'desiredDeliveryDate',
          headerName: 'Delivery Date',
          flex: 1.50,
          type: 'date',
          valueFormatter: ({value}) => value && toDisplayDate(value),
        },
        {
          field: 'createdAt',
          headerName: 'Created',
          flex: 1.50,
          type: 'date',
          valueFormatter: ({value}) => value && timeAgo.format(new Date(value)),
        },
        {
          field: 'actions',
          headerName: '',
          type: 'actions',
          renderCell: ((params) => <Link to={`/orders/${params.row.id}`}><IconButton size={'small'}><ArrowCircleRight color={'primary'} /></IconButton></Link>),
          flex: 0.5,
        },
      ],
      rows: [],
      loading: true,
    };
  }

  handleRowClick = (params, event) => {
    if (event.metaKey || event.ctrlKey || event.shiftKey) {
      return window.open(`/orders/${params.row.id}`, '_blank');
    }

    this.props.navigate(`/orders/${params.row.id}`);
  };

  async componentDidMount() {
    const orders = await getOrders({orderBy: {createdAt: 'desc'}, where: {NOT: {statusKey: 'open'}}, include: {orderedPartConfigs: true}});
    this.setState({
      rows: orders,
      loading: false,
    });
  }

  render() {
    return (
      <Paper sx={{height: '100%'}}>
        <Box sx={{height: '100%'}}>
          <DataGridPro
            onRowClick={this.handleRowClick}
            rows={this.state.rows}
            columns={this.state.columns}
            loading={this.state.loading}
          />
        </Box>
      </Paper>
    );
  }
}
