import {Box, Chip, IconButton, Paper} from '@mui/material';
import {DataGridPro} from '@mui/x-data-grid-pro';
import React, {Component} from 'react';
import {getBundles} from '../../services/knest';
import {toDisplayCurrency, toDisplayDate} from '../../utils/formatters';
import {ArrowCircleRight} from '@mui/icons-material';
import {getColorForStatus} from '../../utils/colormapping';
import {statuses} from '../../utils/status';
import {Link} from 'react-router-dom';

type Props = {
  navigate: Function
}
type State = {
  columns: any[],
  rows: any[],
  loading: boolean,
}

export class BundleTable extends Component <Props, State> {

  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {field: 'id', headerName: 'ID', flex: 0.8},
        {
          field: 'statusKey',
          headerName: 'Status',
          flex: 1.5,
          type: 'singleSelect',
          valueOptions: Object.entries(statuses).map(entry => ({value: entry[0], label: entry[1]})),
          renderCell: ({value}) => <Chip color={getColorForStatus(value)} label={statuses[value]} />,
        },
        {field: 'companyName', headerName: 'Supplier', flex: 4.5},
        {field: 'numberOfPartConfigs', headerName: 'Number of Parts', flex: 1.50},
        {field: 'deliveryDate', headerName: 'Delivery Date', flex: 1.80, type: 'date', valueFormatter: ({value}) => value && toDisplayDate(value)},
        {field: 'price', headerName: 'Total Price', flex: 1, valueFormatter: ({value}) => toDisplayCurrency(value)},
        {
          field: 'actions',
          headerName: '',
          type: 'actions',
          renderCell: ((params) => <Link to={`/bundles/${params.row.id}`}><IconButton size={'small'}><ArrowCircleRight color={'primary'} /></IconButton></Link>),
          flex: 0.5,
        },
      ],
      rows: [],
      loading: true,
    };
  }

  async componentDidMount() {
    const bundles = await getBundles({orderBy: {id: 'desc'}});
    this.setState({
      rows: bundles,
      loading: false,
    });
  }

  handleRowClick = (params, event) => {
    if (event.metaKey || event.ctrlKey || event.shiftKey) {
      return window.open(`/bundles/${params.row.id}`, '_blank');
    }

    this.props.navigate(`/bundles/${params.row.id}`);
  };

  render() {
    return (
      <Paper sx={{height: '100%'}}>
        <Box sx={{height: '100%'}}>
          <DataGridPro
            onRowClick={this.handleRowClick}
            rows={this.state.rows}
            columns={this.state.columns}
            loading={this.state.loading}
            initialState={{filter: {filterModel: {items: [{columnField: 'statusKey', operatorValue: 'not', value: 'lost'}]}}}}
          />
        </Box>
      </Paper>
    );
  }
}
