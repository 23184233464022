import Orders from 'containers/Orders';
import Bundles from 'containers/Bundles';
import Parts from 'containers/Parts';
import MediationIcon from '@mui/icons-material/Mediation';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import {BlankingBacklog} from './containers/BlankingBacklog';
import AssignmentIcon from '@mui/icons-material/Assignment';


const dashRoutes = [
  {
    path: 'orders',
    name: 'orders',
    key: 'Orders',
    icon: <ShoppingCartIcon />,
    component: Orders,
    layout: '/',
  },
  {
    path: 'bundles',
    name: 'bundles',
    key: 'Bundles',
    icon: <MediationIcon />,
    component: Bundles,
    layout: '/',
  },
  {
    path: 'parts',
    name: 'parts',
    key: 'Parts',
    icon: <ViewInArIcon />,
    component: Parts,
    layout: '/',
  },
  {
    path: 'blanking',
    name: 'blanking',
    key: 'Blanking Backlog',
    icon: <AssignmentIcon />,
    component: BlankingBacklog,
    layout: '/',
  },
];
export default dashRoutes;
