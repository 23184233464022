import {createBrowserHistory} from 'history';
import App from './App';
import './i18n';
import React, {setGlobal} from 'reactn';
import {createRoot} from 'react-dom/client';
import {LicenseInfo} from '@mui/x-license-pro';

//Init MUI X Pro
LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_X_PRO_LICENSE);

const hist = createBrowserHistory();

setGlobal({
  userInfo: null,
  history: hist,
});

const root = createRoot(document.getElementById('root'));
root.render(<App history={hist} />);
