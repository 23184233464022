import * as React from 'react';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import {ReactElement} from 'react';
import './MuiBaseModal.scss';
import {CircularProgress, Grid, IconButton, Paper} from '@mui/material';
import {Close} from '@mui/icons-material';
import {FailureIcon, InfoIcon, SuccessIcon} from '../AnimatedIcons/AnimatedIcons';
import {useTranslation} from 'react-i18next';

type Props = {
  open: boolean,
  onToggle: (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => void,
  content: ReactElement,
  stage: BaseModalStage
  header?: ReactElement,
  footer?: ReactElement,
  button?: ReactElement,
  loadingMessageTitle?: string,
  loadingMessageSubtitle?: string,
  successMessageTitle: string,
  successMessageSubtitle?: string,
  errorMessageTitle?: string,
  errorMessageSubtitle?: string,
  infoMessageTitle?: string,
  infoMessageSubtitle?: string,
  height: string,
  width?: string
}

export type BaseModalStage = 'content' | 'loading' | 'success' | 'failure' | 'info'

export const MuiBaseModal = (props: Props) => {
  const headerAndFooterHeight = 0 + (!!props.header ? 80 : 0);
  const {t} = useTranslation('modals');

  const status = (type: BaseModalStage) => {
    return (
      <Grid container justifyContent={'center'} height={'calc(100% - 60px)'}>
        <Grid item xs={12} justifyContent={'center'} display={'flex'} alignItems={'end'}>
          {type === 'success' && <SuccessIcon />}
          {type === 'failure' && <FailureIcon />}
          {type === 'loading' && <CircularProgress />}
          {type === 'info' && <InfoIcon />}
        </Grid>
        <Grid item xs={12}>
          <Grid item xs={12} justifyContent={'center'} display={'flex'}>
            <Typography typography={'h6'}>
              {type === 'success' && props.successMessageTitle}
              {type === 'failure' && (props.errorMessageTitle || t('modals:default_error_message_title'))}
              {type === 'loading' && props.loadingMessageTitle}
              {type === 'info' && props.infoMessageTitle}
            </Typography>
          </Grid>
          <Grid item xs={12} justifyContent={'center'} display={'flex'}>
            <Typography typography={'body'}>
              {type === 'success' && props.successMessageSubtitle}
              {type === 'failure' && (props.errorMessageSubtitle || t('modals:default_error_message_subtitle'))}
              {type === 'loading' && props.loadingMessageSubtitle}
              {type === 'info' && props.infoMessageSubtitle}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <div>
      {props.button ? props.button : <></>}
      <Modal
        open={props.open}
        onClose={props.onToggle}
        closeAfterTransition
      >
        <Fade in={props.open} timeout={250}>
          <div className={'mui-base-modal'} style={{height: props.height, maxHeight: props.height, width: props.width, maxWidth: props.width}}>
            <Paper className={'mui-base-modal-paper'}>
              {/*Close Button*/}
              <IconButton size={'small'} className={'close-mui-base-modal-button'} onClick={props.onToggle as any}>
                <Close />
              </IconButton>

              {/*Header*/}
              {props.header && <div className={'mui-base-modal-header'}>{props.header}</div>}

              {/*Content*/}
              <div className={'mui-base-modal-content'} style={{height: `calc(100% - ${headerAndFooterHeight}px)`, paddingBottom: 20}}>
                {props.stage === 'content' ? props.content : status(props.stage)}
              </div>

              {/*Footer*/}
              {props.footer && <div className={'mui-base-modal-footer'}>{props.footer}</div>}
            </Paper>
          </div>
        </Fade>

      </Modal>
    </div>
  );
};


