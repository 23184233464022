import React from 'react';
import {Grid, Typography} from '@mui/material';
import {BundleTable} from '../components/BundleTable/BundleTable';

const Bundles = (props: any) => {
  return (
    <Grid container alignContent={'flex-start'} sx={{height: '100%'}}>
      <Grid item xs={12} marginTop={'20px'}>
        <Typography variant={'subtitle2'} sx={{color: '#b8b8b8'}}>All Bundles</Typography>
      </Grid>
      <Grid item xs={12} marginBottom={'15px'}>
        <Typography variant={'h2'}>Bundles</Typography>
      </Grid>
      <Grid item xs={12} height={'87vh'}>
        <BundleTable {...props} />
      </Grid>
    </Grid>
  );
};

export default Bundles;
