import {createTheme} from '@mui/material/styles';
import {palette} from './palette';
import {components} from './components';


export const theme = createTheme({
  zIndex: {
    tooltip: 9999,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1200,
      xl: 1536,
    },
  },
  palette,
  components,
  typography: {
    button: {
      textTransform: 'none',
    },
    fontFamily: ['Inter', 'sans-serif'].join(','),
    h1: {
      fontSize: '52px',
      fontWeight: 400,
      color: '#374151',
    },
    h2: {
      fontSize: '30px',
      fontWeight: 500,
      color: '#374151',
    },
    h3: {
      fontSize: '25px',
      fontWeight: 500,
      color: '#374151',
    },
    h4: {
      fontSize: '22px',
      fontWeight: 600,
      color: '#374151',
    },
    h5: {
      fontSize: '20px',
      fontWeight: 600,
      color: '#374151',
    },
    h6: {
      fontSize: '16px',
      fontWeight: 600,
      color: '#374151',
    },
    body1: {
      fontSize: '14px',
      fontWeight: 400,
      color: '#374151',
    },
    subtitle2: {
      fontSize: '12px',
      fontWeight: 500,
      color: '#374151',
      textTransform: 'uppercase',
    },
  },
});