export const getColorForStatus = (statusKey) => {
  const map = {
    accepted: 'success',
    cancelled: 'error',
    closed: '',
    complaint: 'warning',
    counter_offer_accepted: 'warning',
    counter_offer_requested: 'warning',
    counter_offer_submitted: 'warning',
    delayed: 'success',
    delivered: 'success',
    invoice_paid: 'success',
    lost: 'secondary',
    new: 'secondary',
    open: 'primary',
    ordered: 'success',
    partially_delivered: 'success',
    rejected: 'error',
    shipped: 'success',
    placed: 'success',
  };
  return map[statusKey] || 'primary';
};
