import React, {Component, createRef} from 'reactn';
import {MuiBaseModal} from '../MuiBaseModal';
import {Button, Unstable_Grid2 as Grid, Tooltip, Typography, CircularProgress} from '@mui/material';
import {CheckCircleOutline, CloudUpload, Layers, Upload} from '@mui/icons-material';
import {uploadFileToOrderedPartConfig} from '../../../services/knest';
import Dropzone, {DropzoneRef} from 'react-dropzone';
import toast from 'react-hot-toast';

type UploadDropzoneState = {
  loading: boolean,
}

type UploadDropzoneProps = {
  dropzoneRef: React.RefObject<DropzoneRef>
  openFileDialog: Function,
  language: string,
  onUploadComplete: Function,
  orderedPartConfigId: number,
}

class UploadDropzone extends Component<UploadDropzoneProps, UploadDropzoneState> {
  constructor() {
    super();

    this.state = {
      loading: false,
    };
  }

  onDrop = async (acceptedFiles) => {
    await this.setState({loading: true});

    try {
      await toast.promise(uploadFileToOrderedPartConfig(this.props.orderedPartConfigId, acceptedFiles[0], this.props.language), {
        loading: 'Uploading...',
        success: 'Successfully uploaded blanked drawing!',
        error: 'Blanked drawing failed to upload.',
      });

      await this.props.onUploadComplete();
    } catch (e) {}

    this.setState({loading: false});
  };

  render() {
    return <Dropzone
      onDrop={this.onDrop}
      ref={this.props.dropzoneRef}
      accept={{
        'application/pdf': ['.pdf'],
      }}
      noClick
      multiple={false}
      disabled={this.state.loading}
    >
      {({getRootProps, getInputProps, isDragActive}) => (
        <Grid container spacing={2} {...getRootProps()} sx={{borderRadius: 8, border: 1, borderStyle: 'dashed', height: 250}}>
          <input {...getInputProps()} />
          {this.state.loading ?
            <Grid container xs={12} alignContent={'center'} rowSpacing={2}>
              <Grid xs={12} sx={{textAlign: 'center'}}>
                <CircularProgress sx={{fontSize: 80}} />
              </Grid>
              <Grid xs={12} sx={{textAlign: 'center'}}>
                <Typography variant={'h6'}>Uploading...</Typography>
              </Grid>
            </Grid> :
            isDragActive ? (
              <Grid container xs={12} alignContent={'center'} rowSpacing={2}>
                <Grid xs={12} style={{textAlign: 'center'}}>
                  <Layers sx={{fontSize: 80}} color={'primary'} />
                </Grid>
                <Grid xs={12} style={{textAlign: 'center'}}>
                  <Typography variant={'h6'}>
                    Drop files here...
                  </Typography>
                </Grid>
              </Grid>
            ) : (
              // Drag & Drop files
              <Grid container justifyContent={'center'} alignContent={'center'}>
                <Grid xs={12} style={{textAlign: 'center'}}>
                  <CloudUpload sx={{fontSize: 80}} color={'primary'} />
                  <br />
                  <Typography variant={'h6'}>
                    Drag & Drop Here to Upload
                  </Typography>
                </Grid>
                <Grid xs={12} style={{textAlign: 'center'}}>
                  <Typography variant={'body1'}>
                    or, <Button onClick={() => this.props.openFileDialog(this.props.dropzoneRef)}>Browse</Button> to choose files
                  </Typography>
                </Grid>
              </Grid>
            )}
        </Grid>
      )}
    </Dropzone>;
  }
}

type State = {
  open: boolean
  loading: boolean
}

type Props = {
  orderedPartConfig: any
  updateState: Function,
  buttonSize?: 'small' | 'large' | 'medium' | undefined
}

export class UploadBlankedDrawingsModal extends Component<Props, State> {
  private readonly germanDropzoneRef: React.RefObject<DropzoneRef>;
  private readonly englishDropzoneRef: React.RefObject<DropzoneRef>;

  constructor() {
    super();

    this.germanDropzoneRef = createRef();
    this.englishDropzoneRef = createRef();

    this.state = {
      open: false,
      loading: false,
    };
  }

  openFileDialog = (dropzoneRef: React.RefObject<DropzoneRef>) => {
    dropzoneRef?.current?.open();
  };

  content = () => {
    return (
      <Grid container spacing={3}>
        <Grid xs={12} paddingTop={2}>
          <Typography variant={'h3'}>Upload Blanked Drawings</Typography>
        </Grid>
        <Grid container xs={6} spacing={2}>
          <Grid xs={12} display={'flex'} alignItems={'center'} container spacing={1}>
            <Grid xs={'auto'}>
              <Typography variant={'h6'}>German Standardized Drawing</Typography>
            </Grid>
            <Grid xs={'auto'}>
              {this.props.orderedPartConfig.orderedPartConfigOrderedAttachmentTeasers.filter(opcoa => opcoa.languageKey !== 'en').length ?
                <Tooltip title={'German Standardized Drawing Uploaded'}>
                  <CheckCircleOutline color={'success'} />
                </Tooltip> : <></>}
            </Grid>
          </Grid>
          <Grid xs={12}>
            <UploadDropzone
              dropzoneRef={this.germanDropzoneRef}
              openFileDialog={this.openFileDialog}
              language={'de'}
              onUploadComplete={this.props.updateState}
              orderedPartConfigId={this.props.orderedPartConfig.id}
            />
          </Grid>
        </Grid>
        <Grid container xs={6} spacing={2}>
          <Grid xs={12} alignItems={'center'} container spacing={1}>
            <Grid xs={'auto'}>
              <Typography variant={'h6'}>English Standardized Drawing</Typography>
            </Grid>
            <Grid xs={'auto'}>
              {this.props.orderedPartConfig.orderedPartConfigOrderedAttachmentTeasers.filter(opcoa => opcoa.languageKey === 'en').length ?
                <Tooltip title={'English Standardized Drawing Uploaded'}>
                  <CheckCircleOutline color={'success'} />
                </Tooltip> : <></>}
            </Grid>
          </Grid>
          <Grid xs={12}>
            <UploadDropzone
              dropzoneRef={this.englishDropzoneRef}
              openFileDialog={this.openFileDialog}
              language={'en'}
              onUploadComplete={this.props.updateState}
              orderedPartConfigId={this.props.orderedPartConfig.id}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  render() {
    return <MuiBaseModal
      button={<Button
        onClick={() => this.setState({open: !this.state.open})}
        color={'error'}
        variant={'outlined'}
        size={this.props.buttonSize || undefined}
        startIcon={<Upload fontSize={'large'} />}
      >Upload Blanked Drawings</Button>}
      open={this.state.open}
      onToggle={() => {
        this.props.updateState();
        this.setState({open: !this.state.open});
      }}
      content={this.content()}
      height={'70vh'}
      width={'50'}
      stage={'content'}
      successMessageTitle={''}
    />;
  }
}
