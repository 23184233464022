import React from 'react';
import {ProSidebar, Menu, MenuItem, SubMenu} from 'react-pro-sidebar';
import routes from 'routes.js';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {Badge} from '@mui/material';
import './Sidebar.scss';


const Sidebar = (props: any) => {
  const {t} = useTranslation(['sidebar']);

  return (
    <ProSidebar
      collapsed={props.collapsed}
      toggled={props.toggled}
      onToggle={props.handleSidebarToggle}
      breakPoint='md'
      onMouseEnter={() => props.handleSidebarCollapsed(false)}
      onMouseLeave={() => props.handleSidebarCollapsed(true)}
    >
      <Menu iconShape='round' popperArrow>
        {routes.map((route: any) => {
          const pathName = window.location.pathname;
          return (
            <MenuItem
              key={route.name}
              //@ts-ignore
              icon={<Badge badgeContent={<span style={{fontSize: 9}}>{t(route.badgeContent)}</span>} invisible={!route.badgeContent} color={'error'}>{route.icon}</Badge>}
              active={pathName === route.layout + route.path}
              suffix={route.suffix}
              onClick={() => route.function && route.function()}
            >
              {t(route.key)}
              {route.component && <Link to={route.layout + route.path} />}

            </MenuItem>
          );
        })}
      </Menu>
    </ProSidebar>
  );
};

export default Sidebar;