import axios from 'axios';
import {getToken, handleUnauthorized} from './auth';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import {toast} from 'react-hot-toast';

export const kooperAuth = axios.create({
  timeout: 100000,
  baseURL: process.env.REACT_APP_KOOPER_AUTH_URL,
});

export const knest = axios.create({
  timeout: 100000,
  baseURL: `${process.env.REACT_APP_KNEST_KUPPLIAS_URL}`,
});

createAuthRefreshInterceptor(kooperAuth, handleUnauthorized, {
  statusCodes: [401, 403],
  skipWhileRefreshing: false,
});

createAuthRefreshInterceptor(knest, handleUnauthorized, {
  statusCodes: [401, 403],
  skipWhileRefreshing: false,
});

kooperAuth.interceptors.request.use((request) => {
  request.headers.Authorization = getToken();
  return request;
});

knest.interceptors.request.use((request) => {
  request.headers.Authorization = getToken();
  request.headers['access-control-allow-origin'] = '*';
  return request;
});

knest.interceptors.response.use(
  async response => {
    if (response?.config?.url.includes('files')) {
      return response;
    }

    return response.data || response;
  },
  async error => {
    toast.error(<div> {error.response?.data?.message || 'Internal Server Error'} </div>);
    return Promise.reject(error);
  });
