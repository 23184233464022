import {knest, kooperAuth} from './kooperConfig';
import {getToken} from './auth';
import toast from 'react-hot-toast';

require('dotenv').config();

const downloadFile = async (fileResponse) => {
  const fileName = decodeURI(fileResponse.headers['content-disposition']?.split('"')[1]);
  const url = window.URL.createObjectURL(new Blob([fileResponse.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  link.parentNode?.removeChild(link);
};

export const getKooperUser = async () => {
  return kooperAuth.get('/auth/users/current/info');
};

export const getOrders = async (filter = {}): Promise<any> => {
  return knest.get('/ordered-part-list-teasers', {params: {filter}});
};

export const constructOrderedPartConfigThumbnailURL = (orderedPartConfigId) => {
  const userToken = getToken().split('Bearer ')[1];
  return `${process.env.REACT_APP_KNEST_KUPPLIAS_URL}/ordered-part-configs/${orderedPartConfigId}/thumbnail?access_token=${userToken}`;
};

export const getOrder = async (id: number): Promise<any> => {
  return await knest.get(`/ordered-part-list-teasers/${id}`) as any;
};

export const getBundles = async (filter): Promise<any> => {
  return knest.get('/bundle-teasers', {params: {filter}});
};

export const getOrderedPartConfigs = async (filter?): Promise<any> => {
  const orderedPartConfigs = await knest.get('/ordered-part-config-teasers', {
    params: {
      filter: {
        include: {
          bundleOrderedPartConfigTeasers: {include: {bundle: {include: {currentBundleStatus: {include: {kaktusStatus: {include: {status: true}}}}}}}},
          orderedPart: {include: {orderedPartPartFeatures: true}},
          orderedPartConfigProcesses: {include: {process: true}},
          orderedPartConfigReorderTeasers: {include: {supplier: true, bundleOrderedPartConfigTeaser: {include: {orderedPartConfigTeaser: true}}}},
          orderedPartConfigOrderedAttachmentTeasers: true,
        },
        ...filter,
      },
    },
  }) as any;

  for (const _opc of orderedPartConfigs) {
    const dimensionFeatures = _opc.orderedPart.orderedPartPartFeatures
      .filter(oppf => ['height', 'width', 'length', 'outer_diameter', 'thickness'].includes(oppf.partFeatureKey))
      .map(x => parseFloat(x.value))
      .sort((a, b) => a - b);

    const biggestDimension = dimensionFeatures.pop();
    const mediumDimenesion = dimensionFeatures.pop();

    _opc.isHighWeight = parseFloat(_opc.orderedPart.orderedPartPartFeatures.find(oppf => oppf.partFeatureKey === 'weight')?.value) * _opc.batchSize > 80000;
    _opc.isHighDimension = biggestDimension >= 1500 || (biggestDimension >= 1000 && mediumDimenesion >= 1000);
    _opc.isReorder = !!_opc.orderedPartConfigReorderTeasers.length;
    _opc.kaktusStatuses = [...new Set(_opc.bundleOrderedPartConfigTeasers?.map(bopc => bopc.bundle.currentBundleStatus.kaktusStatus.status.categoryKey))];
    _opc.drawingsMissing = [...new Set(_opc.orderedPartConfigOrderedAttachmentTeasers.map(attachment => attachment.languageKey))].length < 2;
  }

  return orderedPartConfigs;
};

export const getOrderedPartConfigsWithoutKSDsInBothLanguages = async () => {
  return knest.get('/ordered-part-config-teasers', {
    params: {
      filter: {
        include: {
          orderedPart: true,
          orderedPartConfigOrderedAttachmentTeasers: true,
          orderedPartListTeaser: {include: {company: true}},
          originalPartConfig: {include: {batchSizes: true}},
        },
        where: {
          createdAt: {
            gt: '2023-01-01T00:00:00.000Z',
          },
          orderedPartListTeaser: {
            NOT: {statusKey: 'cancelled'},
          },
          OR: [
            {orderedPartConfigOrderedAttachmentTeasers: {none: {}}},
            {
              AND: [
                {orderedPartConfigOrderedAttachmentTeasers: {some: {languageKey: 'de'}}},
                {NOT: {orderedPartConfigOrderedAttachmentTeasers: {some: {languageKey: 'en'}}}},
              ],
            },
            {
              AND: [
                {orderedPartConfigOrderedAttachmentTeasers: {some: {languageKey: 'en'}}},
                {NOT: {orderedPartConfigOrderedAttachmentTeasers: {some: {languageKey: 'de'}}}},
              ],
            },
          ],
        },
      },
    },
  });
};

export const getOrderedPartConfig = async (id, filter?): Promise<any> => {
  return await knest.get(`/ordered-part-config-teasers/${id}`, {
    params: {
      filter: {
        include: {
          bundleOrderedPartConfigTeasers: {include: {bundle: {include: {currentBundleStatus: {include: {kaktusStatus: {include: {status: true}}}}}}}},
          orderedPart: {include: {orderedPartPartFeatures: {include: {partFeature: true}}}},
          orderedPartConfigProcesses: {include: {process: true}},
          orderedPartConfigOrderedAttachmentTeasers: true,
        },
        ...filter,
      },
    },
  }) as any;
};
export const openPdf = async () => {};

export const getBundle = async (id: number): Promise<any> => {
  return knest.get(`/bundle-teasers/${id}`, {
    params: {
      filter: {
        include: {
          company: true,
          bundleStatuses: {
            include: {
              bundleStatusFeatureValues: true,
              user: true,
            },
          },
          bundleOrderedPartConfigs: {
            include: {
              orderedPartConfig: {
                include: {
                  orderedPart: {include: {orderedPartPartFeatures: {include: {partFeature: true}}}},
                  orderedPartConfigProcesses: {include: {process: true}},
                  orderedPartConfigOrderedAttachmentTeasers: true,
                },
              },
              orderedPartConfigTeaser: true,
            },
          },
        },
      },
    },
  });
};

export const deleteBundleOrderedPartConfig = async (bundleId, orderedPartConfigId: number) => {
  return knest.delete(`bundles/${bundleId}/orderedPartConfigs/${orderedPartConfigId}`);
};

export const createBundleOrderedPartConfig = async (bundleId, orderedPartConfigId: number) => {
  return knest.post(`bundles/${bundleId}/orderedPartConfigs/${orderedPartConfigId}`);
};

export const updateBundleStatus = async (bundleId, statusKey, statusFeatures) => {
  return knest.post(`/bundles/${bundleId}/status-update`, {statusKey, statusFeatures});
};

export const downloadProjectFiles = async (bundleId) => {
  const filesResponse = await knest.get(`/bundles/${bundleId}/files`, {
    responseType: 'arraybuffer',
  });
  return downloadFile(filesResponse);
};

export const downloadOrderedPartConfigFiles = async (orderedPartConfigId, attachmentTypeKey?) => {
  const toastId = toast.loading('Preparing download...');
  try {
    const filesResponse = await knest.get(`ordered-part-configs/${orderedPartConfigId}/files`, {
      responseType: 'arraybuffer', params: {
        attachmentTypeKey,
        downloadAllLanguages: true,
      },
    });

    await downloadFile(filesResponse);

    toast.success('Files downloaded.', {id: toastId});
  } catch (err) {
    toast.error('Error downloading files. Please try again later.', {id: toastId});
  }
};

export const getSuppliers = async () => {
  return knest.get('/suppliers', {params: {filter: {where: {active: true}, include: {supplierProcesses: {include: {process: true}, where: {active: true}}, companyAddresses: true}}}});
};

export const createManualBundles = async (supplierIds, orderedPartConfigIds) => {
  return knest.post('/bundles/create', {supplierIds, orderedPartConfigIds});
};

export const deleteBundle = async (bundleId) => {
  return knest.delete(`/bundles/${bundleId}`);
};

export const releaseMultipleBundles = async (bundleIds: number[]) => {
  return knest.post('/bundles/release', {bundleIds});
};

export const updateTargetCost = async (bundleId, bopcId, targetCost) => {
  return knest.patch(`/bundles/${bundleId}/bundle-ordered-part-configs/${bopcId}/target-cost`, {value: targetCost || null});
};

export const getProcesses = async () => {
  return knest.get('/processes');
};
export const getSupplierGroups = async () => {
  return knest.get('supplier-groups');
};

export const uploadFileToOrderedPartConfig = async (orderedPartConfigId, file, language, attachmentTypeKey = 'blanked_technical_drawing') => {
  const form = new FormData();
  form.append('file', file);

  return knest.post(`/ordered-part-configs/${orderedPartConfigId}/attachments/${attachmentTypeKey}/upload${language ? `?language=${language}` : ''}`, form);
};
