import moment from 'moment';
import * as DateHolidays from 'date-holidays';
import {GridLinkOperator} from '@mui/x-data-grid-pro';


const dateHolidays = new DateHolidays.default();
dateHolidays.init('DE', undefined, undefined, {types: ['public']});

export const components = {
  MuiFormControlLabel: {
    styleOverrides: {
      root: {
        height: 20,
      },
      label: {
        fontSize: 12,
        color: '#616161',
        fontWeight: 400,
      },
    },
  },
  MuiButton: {
    defaultProps: {
      variant: 'contained',
    },
    styleOverrides: {
      root: {
        borderRadius: '.5rem',
      },
    },
  },
  MuiStaticDatePicker: {
    styleOverrides: {
      backgroundColor: 'black',
    },
    defaultProps: {
      shouldDisableDate: (date) => {
        const isWeekend = ['Sat', 'Sun'].includes(moment(date).format('ddd'));
        const isHoliday = !!dateHolidays.isHoliday(date);
        return isWeekend || isHoliday;
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      formControl: {
        borderRadius: '.625rem',
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        borderRadius: '.625rem',
      },
    },
  },
  MuiDataGrid: {
    styleOverrides: {
      root: {
        '.MuiDataGrid-columnSeparator': {color: 'transparent'},
        '.MuiDataGrid-cell:focus-within': {outlineWidth: '0px'},
        '.MuiDataGrid-row.Mui-selected': {disable: true},

      },
    },
    defaultProps: {
      componentsProps: {
        filterPanel: {
          // Force usage of "And" operator
          linkOperators: [GridLinkOperator.And],
          filterFormProps: {
            // Customize inputs by passing props
            linkOperatorInputProps: {
              variant: 'outlined',
              size: 'small',
            },
            columnInputProps: {
              variant: 'outlined',
              size: 'small',
              sx: {mt: 'auto'},
            },
            operatorInputProps: {
              variant: 'outlined',
              size: 'small',
              sx: {mt: 'auto'},
            },
            valueInputProps: {
              InputComponentProps: {
                variant: 'outlined',
                size: 'small',
              },
            },
            deleteIconProps: {
              sx: {
                '& .MuiSvgIcon-root': {color: '#d32f2f'},
              },
            },
          },
          sx: {
            // Customize inputs using css selectors
            '& .MuiDataGrid-filterForm': {p: 2, width: 900},
            '& .MuiDataGrid-filterFormLinkOperatorInput': {mr: 2},
            '& .MuiDataGrid-filterFormColumnInput': {mr: 2, width: 150},
            '& .MuiDataGrid-filterFormOperatorInput': {mr: 2},
            '& .MuiDataGrid-filterFormValueInput': {width: 200},
          },
        },
      },
    },
  },
};