export const palette = {
  primary: {
    main: '#32BEE1',
    contrastText: '#FFF',
  },
  secondary: {
    main: '#656D79',
  },
  info: {
    main: '#32BEE1',
    contrastText: '#FFF',
  },
  warning: {
    main: '#F7B01E',
    contrastText: '#FFF',
  },
  error: {
    main: '#FF645F',
    contrastText: '#FFF',
  },
  success: {
    main: '#43CCAD',
    contrastText: '#FFF',
  },
};