import React, {Component} from 'reactn';
import {MuiBaseModal} from '../MuiBaseModal';
import {Autocomplete, Button, Grid, IconButton, TextField, Typography} from '@mui/material';
import {AddCircle, CheckCircle, CheckCircleOutline} from '@mui/icons-material';
import {CollapsablePartDetails} from '../../CollapsablePartDetails/CollapsablePartDetails';
import {createBundleOrderedPartConfig, deleteBundleOrderedPartConfig, getOrderedPartConfigs, getOrders} from '../../../services/knest';

type State = {
  open: boolean,
  availableOrderedPartConfigs: any[]
  currentOrderId: any
  openOrders: any[]
  loading: boolean
  currentOrderLocked: boolean
}

type Props = {
  bundle: any,
  onClose: () => void
}

export class AddPartToBundleModal extends Component<Props, State> {
  constructor() {
    super();
    this.state = {
      open: false,
      availableOrderedPartConfigs: [],
      currentOrderId: null,
      currentOrderLocked: false,
      openOrders: [],
      loading: false,
    };
  }

  c;

  async componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.state.open !== prevState.open || this.state.currentOrderId !== prevState.currentOrderId) {
      this.setState({loading: true});

      if (this.props.bundle.bundleOrderedPartConfigs.length) {
        this.setState({currentOrderId: this.props.bundle.bundleOrderedPartConfigs[0].orderedPartConfig.orderedPartListId, currentOrderLocked: true});
      } else {
        this.setState({currentOrderLocked: false});
      }

      const orders = await getOrders({
        select: {id: true},
        orderBy: {id: 'desc'},
      });

      const availableOrderedPartConfigs = await getOrderedPartConfigs({
        where: {
          AND: [
            {NOT: {id: {in: this.props.bundle.bundleOrderedPartConfigs.map(bopc => bopc.orderedPartConfigId)}}},
            {orderedPartListId: this.state.currentOrderId},
          ],
        },
      });

      await this.setState({
        availableOrderedPartConfigs,
        openOrders: orders.map(order => order.id),
        loading: false,
      });
    }
  }

  handleAddOpcToBundle = async (opc) => {
    await createBundleOrderedPartConfig(this.props.bundle.id, opc.id);
    this.setState({
      currentOrderLocked: true,
      availableOrderedPartConfigs: this.state.availableOrderedPartConfigs.map(availableOpc => {
        if (availableOpc.id === opc.id) {
          return {...availableOpc, selected: true};
        }
        return availableOpc;
      }),
    });
  };

  handleRemoveOpcFromBundle = async (opc) => {
    await deleteBundleOrderedPartConfig(this.props.bundle.id, opc.id);

    const availableOrderedPartConfigs = this.state.availableOrderedPartConfigs.map(availableOpc => {
      if (availableOpc.id === opc.id) {
        return {...availableOpc, selected: false};
      }
      return availableOpc;
    });

    if (!availableOrderedPartConfigs.map(x => !!x.selected).filter(x => !!x).length) {
      this.setState({currentOrderLocked: false});
    }

    await this.setState({availableOrderedPartConfigs});

  };

  renderAvailablePartConfigs = () => {
    if (this.state.loading) {
      return <>Loading...</>;
    }
    if (!this.state.availableOrderedPartConfigs.length) {
      return <>No more parts available</>;
    }

    return this.state.availableOrderedPartConfigs.map((opc) => {
      return (
        <CollapsablePartDetails
          orderedPartConfig={opc}
          buttons={
            <>
              {opc.selected ?
                <IconButton onClick={() => this.handleRemoveOpcFromBundle(opc)}>
                  <CheckCircle color={'success'} fontSize={'large'} />
                </IconButton> :
                <IconButton onClick={() => this.handleAddOpcToBundle(opc)}>
                  <CheckCircleOutline fontSize={'large'} />
                </IconButton>
              }
            </>
          }
        />
      );
    });
  };

  content = () => {
    return (
      <Grid container xs={12}>
        <Grid item xs={12} paddingTop={2} paddingBottom={5}>
          <Typography variant={'h3'}>Available Parts for this Bundle</Typography>
        </Grid>
        <Grid item container xs={12} spacing={2}>
          <Grid item xs={2} display={'flex'} alignItems={'center'}>
            <Typography variant={'h6'}>Select Order</Typography>
          </Grid>
          <Grid item xs={10}>
            <Autocomplete
              options={this.state.openOrders}
              value={this.state.currentOrderId}
              onChange={(event, newValue) => this.setState({currentOrderId: newValue})}
              getOptionLabel={option => `KMS-${option}`}
              disabled={this.state.currentOrderLocked}
              renderInput={params => <TextField {...params} label={'Select Order'} sx={{width: 200}} />}
            />
          </Grid>
          {this.renderAvailablePartConfigs()}
        </Grid>
      </Grid>
    );
  };

  render() {
    return <MuiBaseModal
      button={<Button startIcon={<AddCircle fontSize={'large'} />} onClick={() => this.setState({open: !this.state.open})}>Add Part to Bundle</Button>}
      open={this.state.open}
      onToggle={() => {
        this.state.open && this.props.onClose();
        this.setState({open: !this.state.open});
      }}
      content={this.content()}
      height={'90vh'}
      width={'90%'}
      stage={'content'}
      successMessageTitle={''}
    />;
  }
}