import React, {Component} from 'reactn';
import {MuiBaseModal} from '../MuiBaseModal';
import {Autocomplete, Button, Grid, IconButton, TextField, Typography} from '@mui/material';
import {AddCircle, CheckCircle, CheckCircleOutline, DoubleArrow} from '@mui/icons-material';
import {createManualBundles, getProcesses, getSupplierGroups, getSuppliers} from '../../../services/knest';
import {CollapsablePartDetails} from '../../CollapsablePartDetails/CollapsablePartDetails';
import {DataGridPro} from '@mui/x-data-grid-pro';
import i18n from '../../../i18n';

type State = {
  open: boolean,
  suppliers: any[],
  order: any,
  selectedSuppliers: any[]
  stage: any,
  mainProcesses: any[],
  postProcesses: any[],
  supplierGroups: any[]
  filterModelItems: any[],
  addresses: any[],
  orderedPartConfigs: any[]
}

type Props = {
  order: any
  onClose: Function,
  orderedPartConfigs: any[]
}

export class CreateBundlesModal extends Component<Props, State> {
  private dataGridRef: React.RefObject<unknown>;

  constructor() {
    super();
    this.state = {
      open: false,
      suppliers: [],
      addresses: [],
      order: null,
      selectedSuppliers: [],
      stage: 'content',
      mainProcesses: [],
      postProcesses: [],
      supplierGroups: [],
      filterModelItems: [],
      orderedPartConfigs: [],
    };
    this.dataGridRef = React.createRef();

  }

  async componentDidMount() {
    let suppliers = await getSuppliers() as any;
    const processes = await getProcesses() as any;
    const supplierGroups = await getSupplierGroups() as any;

    const hardCodedSupplierRule = {
      customerId: 2803, // JULIUS BLUM GMBH
      excludedSuppliers: [3406, 3619], // STIWA Advanced Products GmbH, STICHT Technologie GmbH,
    };

    if (this.props.order.companyId === hardCodedSupplierRule.customerId) {
      suppliers = suppliers.filter(s => !hardCodedSupplierRule.excludedSuppliers.includes(s.id));
    }
    this.setState({
      mainProcesses: processes.filter(p => p.class === 'process'),
      postProcesses: processes.filter(p => p.class === 'post_process'),
      supplierGroups,
      suppliers,
      addresses: [...new Set(suppliers.map(x => x.companyAddresses[0]?.countryKey).filter(x => !!x))],
      order: this.props.order,
      orderedPartConfigs: this.props.orderedPartConfigs,
    });
  }

  handlePartToggle = (opcId, action: 'add' | 'remove') => {
    const newOpcs = this.state.orderedPartConfigs.map(opc => {
      if (opc.id === opcId) {
        return {...opc, selected: action === 'add'};
      }
      return opc;
    });
    this.setState({orderedPartConfigs: newOpcs});
  };

  handleCreateBundles = async () => {
    this.setState({stage: 'loading'});
    try {

      const parts = this.state.orderedPartConfigs.filter(x => x.selected);
      await createManualBundles(this.state.selectedSuppliers, parts.map(x => x.id));
      this.setState({stage: 'success', selectedSuppliers: []});
    } catch (e) {
      this.setState({stage: 'failure'});
    }
  };

  filterToolbar = () => {
    const {t} = i18n;

    return <Grid container columns={10} display={'flex'} spacing={2} padding={2} paddingLeft={5}>
      <Grid item xs={4}>
        <Autocomplete
          filterSelectedOptions
          size={'small'}
          renderInput={(props) => <TextField  {...props} label={'Supplier Name'} />}
          multiple
          getOptionLabel={option => option.companyName}
          options={this.state.suppliers}
          value={this.state.suppliers.filter(supplier => this.state.filterModelItems.find(f => f.columnField === 'companyName')?.value.includes(supplier.companyName))}
          onChange={(e, values) => {
            const newFilter = this.state.filterModelItems.filter(x => x.columnField !== 'companyName');
            newFilter.push({id: Math.random(), columnField: 'companyName', operatorValue: 'isAnyOf', value: values.map(value => value.companyName)});
            this.setState({filterModelItems: newFilter});
          }}
        />
      </Grid>
      <Grid item xs={1}>
        <Autocomplete
          filterSelectedOptions
          size={'small'}
          renderInput={(props) => <TextField {...props} label={'Supplier Group'} />}
          options={this.state.supplierGroups}
          getOptionLabel={option => option.key}
          value={this.state.supplierGroups.find(sg => this.state.filterModelItems.find(f => f.columnField === 'supplierGroup')?.value.includes(sg.key))}
          onChange={(e, values) => {
            const newFilter = this.state.filterModelItems.filter(x => x.columnField !== 'supplierGroup');
            values && newFilter.push({id: Math.random(), columnField: 'supplierGroup', operatorValue: 'isAnyOf', value: [values.key]});
            this.setState({filterModelItems: newFilter});
          }}
        />
      </Grid>
      <Grid item xs={1}>
        <Autocomplete
          filterSelectedOptions
          size={'small'}
          getOptionLabel={option => option}
          renderInput={(props) => <TextField {...props} label={'Country'} />}
          options={this.state.addresses || []}
          value={this.state.addresses.find(c => this.state.filterModelItems.find(f => f.columnField === 'companyAddresses')?.value.includes(c))}
          onChange={(e, values) => {
            const newFilter = this.state.filterModelItems.filter(x => x.columnField !== 'companyAddresses');
            values && newFilter.push({id: Math.random(), columnField: 'companyAddresses', operatorValue: 'isAnyOf', value: [values]});
            this.setState({filterModelItems: newFilter});
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <Autocomplete
          filterSelectedOptions
          size={'small'}
          renderInput={(props) => <TextField {...props} label={'Main Processes'} />}
          multiple
          options={this.state.mainProcesses}
          getOptionLabel={option => t(`processes:${option.key}`)}
          value={this.state.mainProcesses.filter(mp => this.state.filterModelItems.filter(f => f.columnField === 'mainProcess').map(f => f.value).includes(mp.key))}
          onChange={(e, values) => {
            const newFilter = this.state.filterModelItems.filter(x => x.columnField !== 'mainProcess');
            values.forEach(value => newFilter.push({id: Math.random(), columnField: 'mainProcess', operatorValue: 'contains', value: value.key}));
            this.setState({filterModelItems: newFilter});
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <Autocomplete
          filterSelectedOptions
          size={'small'}
          renderInput={(props) => <TextField {...props} label={'Post Processes'} />}
          multiple
          options={this.state.postProcesses}
          getOptionLabel={option => t(`processes:${option.key}`)}
          value={this.state.postProcesses.filter(mp => this.state.filterModelItems.filter(f => f.columnField === 'postProcess').map(f => f.value).includes(mp.key))}
          onChange={(e, values) => {
            const newFilter = this.state.filterModelItems.filter(x => x.columnField !== 'postProcess');
            values.forEach(value => newFilter.push({id: Math.random(), columnField: 'postProcess', operatorValue: 'contains', value: value.key}));
            this.setState({filterModelItems: newFilter});
          }}
        />
      </Grid>
    </Grid>;
  };

  content = () => {
    if (!this.state.order) {
      return <></>;
    }

    const {t} = i18n;

    return (
      <Grid container xs={12}>
        <Grid item xs={12} paddingTop={2} paddingBottom={5}>
          <Typography variant={'h3'}>Create new Bundles for this Order</Typography>
        </Grid>
        <Grid item container xs={12} spacing={2}>
          <Grid item xs={12} display={'flex'} alignItems={'center'}>
            <Typography variant={'h6'}>Select Suppliers</Typography>
          </Grid>

          <Grid item xs={12} height={'60vh'}>
            <DataGridPro
              filterModel={{items: this.state.filterModelItems}}
              components={{
                Toolbar: () => this.filterToolbar(),
              }}
              checkboxSelection
              onFilterModelChange={(model) => {this.setState({filterModelItems: model.items});}}
              onSelectionModelChange={(value) => this.setState({selectedSuppliers: value})}
              selectionModel={this.state.selectedSuppliers}
              columns={[
                {field: 'companyName', flex: 4, headerName: 'Supplier Name'},
                {field: 'supplierGroup', flex: 1, headerName: 'Group'},
                {field: 'companyAddresses', flex: 1, headerName: 'Country', valueGetter: ({value}) => value[0]?.countryKey},
                {
                  field: 'mainProcess',
                  flex: 2,
                  headerName: 'Main Processes',
                  valueGetter: (params) => params.row.supplierProcesses.filter(sp => sp.process.class === 'process').map(sp => sp.processKey),
                  valueFormatter: (params) => params.value.map(v => t(`processes:${v}`)).join(', '),
                  hide: false,
                },
                {
                  field: 'postProcess',
                  flex: 2,
                  headerName: 'Post Processes',
                  valueGetter: (params) => params.row.supplierProcesses.filter(sp => sp.process.class === 'post_process').map(sp => sp.processKey),
                  valueFormatter: (params) => params.value.map(v => t(`processes:${v}`)).join(', '),
                  hide: false,
                },
              ]}
              rows={this.state.suppliers}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant={'h6'}>Select Parts</Typography>
          </Grid>

          <Grid item container xs={12} spacing={2}>
            {this.state.orderedPartConfigs
              .filter(_opc => _opc.bundleOrderedPartConfigTeasers.some(bopct => bopct.isInComplaint) || !_opc.kaktusStatuses.includes('placed'))
              .map(opc =>
                <CollapsablePartDetails
                  orderedPartConfig={opc}
                  buttons={
                    <>
                      {opc.selected ?
                        <IconButton onClick={() => this.handlePartToggle(opc.id, 'remove')}>
                          <CheckCircle color={'success'} fontSize={'large'} />
                        </IconButton> :
                        <IconButton onClick={() => this.handlePartToggle(opc.id, 'add')}>
                          <CheckCircleOutline fontSize={'large'} />
                        </IconButton>
                      }
                    </>
                  }
                />,
              )}
          </Grid>

          <Grid item xs={12} display={'flex'} justifyContent={'flex-end'}>
            <Button
              disabled={!this.state.selectedSuppliers.length || !this.state.orderedPartConfigs.filter(x => x.selected).length}
              onClick={() => this.handleCreateBundles()}
              endIcon={<DoubleArrow />}
              color={'success'}
            >Create</Button>
          </Grid>

        </Grid>
      </Grid>
    );
  };

  render() {
    return <MuiBaseModal
      button={<Button variant={'outlined'} startIcon={<AddCircle fontSize={'large'} />} onClick={() => this.setState({open: !this.state.open})}>Create Bundles</Button>}
      open={this.state.open}
      onToggle={() => {
        this.state.open && this.props.onClose();
        this.setState({open: !this.state.open, stage: 'content', orderedPartConfigs: this.state.orderedPartConfigs.map(x => ({...x, selected: undefined}))});
      }}
      content={this.content()}
      height={'90vh'}
      width={'90vw'}
      stage={this.state.stage}
      successMessageTitle={'Bundles have been created successfully'}
    />;
  }
}
